import classNames from "classnames";
import { scrollTo } from "seamless-scroll-polyfill";
import useScreenDimensions from "../../../hooks/useScreenDimensions";
import ExternalLink from "../../shared/ExternalLink";
import styles from "./index.module.scss";
import { ReactComponent as LogoFull } from "../../../assets/icons/logo-full.svg";
import { useAppSelector } from "../../../store/hooks";
import constants from "../../../utils/constants";
import { LanguageSelect } from "../../shared/LanguageSelect/LanguageSelect";
import { t } from "i18next";

const Header = () => {
  const { scrollY } = useScreenDimensions();
  const { logoRotaion } = useAppSelector(state => state.colorTheme);

  const handleLogoClick = () => {
    scrollTo(window, {
      behavior: "smooth",
      top: 0,
    });
  };

  return (
    <header
      className={classNames(styles.container, {
        [styles.container_active]: scrollY > 0,
      })}
    >
      <LogoFull
        className={classNames(
          styles.logo,
          { [styles.logo_visible]: scrollY },
          logoRotaion && styles.logo_animation,
        )}
        onClick={handleLogoClick}
      />

      <div className={styles.links}>
        <LanguageSelect />
        <div className={styles.loginContainer}>
          <ExternalLink
            label={t("Login")}
            variant="text"
            path={constants.routes.SIGNIN}
          />
          <ExternalLink
            label={t("Get started")}
            variant="button"
            path={constants.routes.QUESTIONARE}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
