import React from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../store/hooks";
import { PERSONAL_ADVANTAGES } from "../../../utils/constants/content";

import styles from "./index.module.scss";
import classNames from "classnames";
import Card from "./molecules/Card/Card";

const Personal = () => {
  const { t } = useTranslation();
  const { current } = useAppSelector(state => state.colorTheme);

  return (
    <div className={styles.container} id="personal">
      <div className={styles.content}>
        <div className={styles.text}>
          <p className={styles.title}>{t("4HUB for Personal")}</p>

          <p className={styles.description}>
            {t(
              "Smart workspace for you. For freelancers and solo workers. Organize the space and at any time have access to the file from anywhere in the world.",
            )}
          </p>
        </div>

        <div
          className={classNames(
            styles.advantages,
            styles[`advantages_${current}`],
          )}
        >
          {Object.keys(PERSONAL_ADVANTAGES).map((advantageKey, index) => (
            <Card key={index} item={PERSONAL_ADVANTAGES[advantageKey]} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Personal;
