import React from "react";
import { useTranslation } from "react-i18next";

import { BUSINESS_SLIDER_ITEMS } from "../../../utils/constants/content";

import Carousel from "../../shared/Carousel";

import styles from "./index.module.scss";
import Slide from "./molecules/Slide/Slide";

const Business = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container} id="business">
        <div className={styles.text}>
          <p className={styles.title}>{t("4HUB for Business.")}</p>

          <p className={styles.description}>
            {t(
              "For businesses and workgroups of any size.Your desktop computer for work. All communication and storage of files on a single platform to improve productivity, optimize time and company security.",
            )}
          </p>
        </div>
      </div>
      <Carousel>
        {Object.keys(BUSINESS_SLIDER_ITEMS).map(itemKey => (
          <Slide key={itemKey} item={BUSINESS_SLIDER_ITEMS[itemKey]} />
        ))}
      </Carousel>
    </>
  );
};

export default Business;
