import { t } from "i18next";
import { AuthButton } from "../../shared/AuthButton";
import { Wrap } from "../SignUp/SignUp.styled";
import { Subtitle, WorkspaceBox, WorkspaceText } from "./SingInPage.styled";
import { AuthInput } from "../../shared/AuthInput";
import { ChangeEvent, useState } from "react";

const SingInPage = () => {
  const [workspace, setWorkspace] = useState<string>("");

  const handleWorkspaceChange = (event: ChangeEvent<HTMLInputElement>) =>
    setWorkspace(event.target.value.toLocaleLowerCase());

  const onSubmit = () =>
    (window.location.href = `https://${workspace}.mh.net.ua/signin`);

  return (
    <Wrap>
      <h4>{t("auth.SignIpTitle")}</h4>

      <Subtitle>{t("auth.signIpSubtitle")}</Subtitle>

      <WorkspaceBox>
        <AuthInput
          value={workspace}
          onChange={handleWorkspaceChange}
          placeholder={t("short.workspace")}
        />
        <WorkspaceText>.4Hub.com</WorkspaceText>
      </WorkspaceBox>
      <AuthButton confirmButton onClick={onSubmit} disabled={!workspace}>
        {t("short.continue")}
      </AuthButton>
    </Wrap>
  );
};

export default SingInPage;
