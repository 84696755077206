import React from "react";
import classNames from "classnames";

import { SwitchProps } from "../types";

import SvgIcon from "../SvgIcon";

import styles from "./index.module.scss";

const Switch = ({ icons, text, checked, onChange }: SwitchProps) => {
  return (
    <div className={styles.container}>
      {icons?.left && (
        <SvgIcon
          type={icons.left}
          onClick={() => onChange(false)}
          className={classNames(styles.icon, {
            [styles.icon_active]: !checked,
          })}
        />
      )}

      {text?.left && (
        <p
          onClick={() => onChange(false)}
          className={classNames(styles.text, {
            [styles.text_active]: !checked,
          })}
        >
          {text.left}
        </p>
      )}

      <div
        onClick={() => onChange(!checked)}
        className={classNames(styles.switch, {
          [styles.switch_active]: checked,
        })}
      >
        <div
          className={classNames(styles.switch_indicator, {
            [styles.switch_indicator_active]: checked,
          })}
        />
      </div>

      {text?.right && (
        <p
          onClick={() => onChange(true)}
          className={classNames(styles.text, {
            [styles.text_active]: checked,
          })}
        >
          {text.right}
        </p>
      )}

      {icons?.right && (
        <SvgIcon
          onClick={() => onChange(true)}
          type={icons.right}
          className={classNames(styles.icon, { [styles.icon_active]: checked })}
        />
      )}
    </div>
  );
};

export default Switch;
