import React from "react";
import classNames from "classnames";

import { ExternalLinkProps } from "../types";
import { useAppSelector } from "../../../store/hooks";

import styles from "./index.module.scss";
import { Link } from "react-router-dom";

const ExternalLink = ({ label, variant, path }: ExternalLinkProps) => {
  const { current } = useAppSelector(state => state.colorTheme);

  return (
    <Link
      to={path}
      className={classNames(
        styles.link,
        styles[`link_${variant}`],
        styles[`link_${variant}_${current}`],
      )}
    >
      <div className={styles.circle} />
      <span>{label}</span>
    </Link>
  );
};

export default ExternalLink;
