import React, { useEffect } from "react";

import { MainLayoutProps } from "../types";
import ToastContainer from "../../containers/ToastContainer";
import Header from "../../base/Header";
import Footer from "../../base/Footer";
import Background from "../../base/Background";
import { toggleLogoRotation } from "../../../store/actions/colorTheme";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

const MainLayout = ({ children }: MainLayoutProps) => {
  const { logoRotaion } = useAppSelector(state => state.colorTheme);
  const dispatch = useAppDispatch();

  const handlePageClick = () => {
    if (logoRotaion) return;
    dispatch(toggleLogoRotation());
  };

  useEffect(() => {
    const handleClick = () => {
      handlePageClick();
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, []);

  useEffect(() => {
    if (!logoRotaion) return;
    setTimeout(() => {
      handlePageClick();
    }, 1000);
  }, [logoRotaion]);

  return (
    <ToastContainer>
      <Header />
      <Background />
      <main>{children}</main>
      <Footer />
    </ToastContainer>
  );
};

export default MainLayout;
