import { useEffect, useState } from "react";

import { ScreenDimensions } from "./types";

const useScreenDimensions = () => {
  const [dimensions, setDimensions] = useState<ScreenDimensions>({
    width: 0,
    height: 0,
    orientation: "landscape",
  });

  const [scrollY, setScrollY] = useState(0);

  const handleResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const orientation = width > height ? "landscape" : "portrait";

    setDimensions({ ...dimensions, width, height, orientation });
  };

  const handleScroll = () => {
    const windowScrollY = window.scrollY;
    const shouldUpdate = scrollY === 0 || windowScrollY === 0;

    if (shouldUpdate) {
      setScrollY(windowScrollY);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, true);
    window.addEventListener("scroll", handleScroll, true);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize, true);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  return { ...dimensions, scrollY };
};

export default useScreenDimensions;
