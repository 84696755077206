// LAYOUTS
import AuthLayout from "../../components/layouts/AuthLayout/AuthLayout";
import MainLayout from "../../components/layouts/Main";
import QuestionareLayout from "../../components/layouts/QuestionareLayout/QuestionareLayout";

// PAGES
import MainPage from "../../components/pages/Main";
// import SignUpPage from "../../components/pages/SignUpPage/SignUpPage";
import QuestionnairePage from "../../components/pages/Questionnaire/Questionnaire";
import SignUp from "../../components/pages/SignUp/SignUp";
import SingInPage from "../../components/pages/SingInPage/SingInPage";

// ROUTES
export const ROOT = "/";
export const SIGNUP = "signUp";
export const QUESTIONARE = "questionare";
export const SIGNIN = "signIn";

export const PUBLIC_ROUTES = {
  [ROOT]: { element: MainPage, layout: MainLayout },
  [SIGNUP]: { element: SignUp, layout: AuthLayout },
  [QUESTIONARE]: { element: QuestionnairePage, layout: QuestionareLayout },
  [SIGNIN]: { element: SingInPage, layout: AuthLayout },
};
