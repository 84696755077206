import { ReactComponent as CheckIcon } from "../../../../../../../assets/icons/checkmark.svg";
import { media } from "../../../../../../../styles/sizes";

import styled from "styled-components";

export const SidebarListWrap = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 48px;
  height: 332px;
  max-height: 1200px;
  background: #23216a;
  gap: 20px;
  overflow: scroll;
  @media screen and (${media.md}) {
    height: 90vh;
  }
`;

export const SidebarListItem = styled.div`
  border-radius: 30px;
  padding: 30px 25px;
  position: relative;
`;

export const SidebarListTitle = styled.h5`
  font-weight: 590;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.432px;
  color: #ffffff;
  display: flex;
  align-items: center;
`;

export const SidebarListText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
`;

export const SidebarListRightIcon = styled(CheckIcon)`
  position: absolute;
  right: 25px;
  top: 40px;
  width: 24px;
  height: 24px;
  svg {
    stroke: #fff;
  }
`;
