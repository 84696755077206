import { useTranslation } from "react-i18next";

const useCurrentLanguage = () => {
  const { i18n } = useTranslation();
  const currentLang = localStorage.getItem("APP_LANG") || i18n.language;

  return currentLang;
};

export default useCurrentLanguage;
