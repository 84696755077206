import { SidebarImageWrap } from "./SidebarImage.styled";

interface SidebarImageProps {
  title: string;
  text: string;
  img: string;
  position: string;
}

export const SidebarImage = ({ data }: { data: SidebarImageProps }) => {
  const { title, text, img, position } = data;
  return (
    <SidebarImageWrap>
      <h4>{title}</h4>
      <p>{text}</p>
      <div
        style={{
          backgroundImage: `url('${img}')`,
          backgroundPosition: position,
        }}
      />
    </SidebarImageWrap>
  );
};
