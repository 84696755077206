import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg";
import {
  DesktopHeader,
  LogoIcon,
  QuestionnareButton,
  QuestionnareButtons,
  QuestionnareContent,
  QuestionnareFooter,
  QuestionnareSlider,
  QuestionnareWrap,
} from "./Questionnare.styles";
import { t } from "i18next";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { questionData, sliderData } from "./data";
import constants from "../../../utils/constants";
import { LanguageSelect } from "../../shared/LanguageSelect/LanguageSelect";
import { AuthButton } from "../../shared/AuthButton";
import { Question } from "./components/Question";
import { AuthSlider } from "../../shared/AuthSlider";
import { QuestionnareSidebar } from "./components/QuestionnareSidebar";

export interface IQuestion {
  title: string;
  answers: string[];
}

export interface IAnswer {
  question: string;
  answers: string[];
}

const Questionnaire = () => {
  const [active, setActive] = useState<number>(0);
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const navigate = useNavigate();

  const updateAnswer = (currentAnswers: string[], selectedAnswer: string) => {
    if (currentAnswers.includes(selectedAnswer)) {
      return currentAnswers.filter(item => item !== selectedAnswer);
    } else {
      return [...currentAnswers, selectedAnswer];
    }
  };

  const onSelectAnswer = (selectedAnswer: string) => {
    const updatedAnswers = [...answers];
    const currentQuestionData = questionData[active];

    if (currentQuestionData?.images) {
      updatedAnswers[active] = {
        question: currentQuestionData.title,
        answers: updateAnswer(answers[active]?.answers || [], selectedAnswer),
      };
    } else {
      const currentAnswers = updatedAnswers[active]?.answers || [];
      updatedAnswers[active] = {
        question: currentQuestionData.title,
        answers: currentAnswers[0] === selectedAnswer ? [] : [selectedAnswer],
      };
    }

    setAnswers(updatedAnswers);
  };

  const toggleNextButton = () => {
    if (
      questionData.length === answers.length ||
      active === questionData.length - 1
    ) {
      navigate(`/${constants.routes.SIGNUP}`, { state: { answers } });
    }
    setActive(prevActive =>
      prevActive < questionData.length - 1 ? prevActive + 1 : prevActive,
    );
  };

  const toggleBackButton = () => {
    setActive(prevActive => (prevActive > 0 ? prevActive - 1 : 0));
  };

  return (
    <QuestionnareWrap>
      <QuestionnareContent>
        <DesktopHeader>
          <Link to="https://fslanding.mh.net.ua/">
            <LogoIcon />
          </Link>
          <LanguageSelect />
        </DesktopHeader>
        <Question
          length={active + 1 + "/" + questionData.length}
          question={questionData[active].title}
          answers={questionData[active].answers}
          activeAnswers={answers[active]?.answers}
          onSelectAnswer={onSelectAnswer}
        />
        <QuestionnareFooter>
          <QuestionnareButton onClick={toggleBackButton}>
            <ArrowIcon />
            {t("short.back")}
          </QuestionnareButton>
          <QuestionnareButtons>
            <QuestionnareButton onClick={toggleNextButton}>
              {t("short.skip")}
            </QuestionnareButton>
            <AuthButton
              questionnaireButton
              disabled={
                answers[active]?.answers?.length === 0 ||
                !answers[active]?.answers
              }
              onClick={toggleNextButton}
            >
              {t("short.next")}
            </AuthButton>
          </QuestionnareButtons>
        </QuestionnareFooter>
      </QuestionnareContent>
      <QuestionnareSlider>
        {questionData[active].images && answers[active]?.answers.length > 0 ? (
          <QuestionnareSidebar
            question={questionData[active]}
            answer={answers[active]}
          />
        ) : (
          <AuthSlider
            data={sliderData()}
            activeSlideIndex={active}
            autoplay={false}
          />
        )}
      </QuestionnareSlider>
    </QuestionnareWrap>
  );
};

export default Questionnaire;
