import ApiClient from "./ApiClient";
import FileApi from "./File";
import RegAPI from "./RegApi";
import { ApiFactory, ApiClientInterface } from "./types";

export default function apiConstruct({
  apiUrl,
  apiPrefix,
  onError,
}: ApiFactory) {
  if (!apiUrl || !apiPrefix) {
    throw new Error("[apiUrl] and [apiPrefix] required");
  }

  const apiClient: ApiClientInterface = new ApiClient({
    apiUrl,
    apiPrefix,
    onError,
  });

  return {
    apiClient,
    file: new FileApi({ apiClient }),
    reg: new RegAPI({ apiClient }),
  };
}
