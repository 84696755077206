// import { useGoogleAuth } from "@hooks/useGoogle";
import /* useEffect */ "react";
import { useGoogleLogin } from "@react-oauth/google";

import { ReactComponent as GoogleIcon } from "../../../assets/icons/google.svg";
import { AuthButton } from "../AuthButton";
import { Dispatch, FC, SetStateAction } from "react";

interface IGoogleButtonProps {
  setError: Dispatch<SetStateAction<boolean>>;
  setGoogleData: (data: string) => void;
}

export const GoogleButton: FC<IGoogleButtonProps> = ({
  setError,
  setGoogleData,
}): JSX.Element => {
  const onSuccessGoogleLogin = async (response: { access_token: string }) => {
    setError(false);
    const accessToken = response.access_token;
    try {
      const userInfoResponse = await fetch(
        "https://www.googleapis.com/oauth2/v2/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      if (userInfoResponse.ok) {
        console.log("userInfoResponse", userInfoResponse);

        const userInfo = await userInfoResponse.json();
        setGoogleData(JSON.stringify(userInfo));
        // const response = await getTimezoneIp();
        // const formData = new FormData();
        // formData.append("google", JSON.stringify(userInfo));
        // formData.append("gmt", getGmt());
        // formData.append("is_gmt_auto", "true");
        // formData.append("timeZone", response.data.timezone);
        // formData.append(
        //   "lang",
        //   localStorage.getItem("selectedLang") || i18next.language,
        // );
        // formData.append("dateFormat", getUserDateFormat());
        // const { data } = await api.post(`/ajax/user_login.php`, formData);
        // addHeaders(data.uid);
        // // onlog(data.uid);
      }
    } catch (error) {
      setError(true);
      console.error(error);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: onSuccessGoogleLogin,
  });

  return (
    <AuthButton onClick={() => googleLogin()}>
      <GoogleIcon />
    </AuthButton>
  );
};

// import api, { addHeaders } from "@api";
// import { getTimezoneIp } from "@api/TimezoneApi";
// import { AuthButton } from "@components/ui/AuthButton";
// import { useActions } from "@hooks/useActions";
// import { getGmt } from "@utils/getGmt";
// import { getUserDateFormat } from "@utils/getTimeFormat";
// import { setUserToStorage } from "@utils/signIn";
// import i18next from "i18next";
// import { Dispatch, SetStateAction } from "react";

// import GoogleIcon from "@/assets/icons/google.svg?react";

// export const GoogleButton = ({
//   setError,
//   rememberUser,
// }: {
//   setError: Dispatch<SetStateAction<boolean>>;
//   rememberUser: boolean;
// }): JSX.Element => {
//   const { onlog } = useActions();

//   return (
//     <AuthButton onClick={() => googleLogin()}>
//       <GoogleIcon />
//     </AuthButton>
//   );
// };
