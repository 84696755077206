import React from "react";
import NukaCarousel from "nuka-carousel";

import { CarouselProps } from "../types";
import useScreenDimensions from "../../../hooks/useScreenDimensions";

const Carousel = ({ children }: CarouselProps) => {
  const { width } = useScreenDimensions();

  let slidesToShow = 1.1;

  if (width >= 2500) {
    slidesToShow = 5;
  } else if (width >= 1900) {
    slidesToShow = 4;
  } else if (width >= 1400) {
    slidesToShow = 3;
  } else if (width >= 768) {
    slidesToShow = 2;
  }

  const settings = {
    withoutControls: true,
    autoplay: true,
    wrapAround: true,
    cellSpacing: width < 1200 ? 20 : 26,
    slidesToShow,
  };

  return (
    <NukaCarousel
      style={{
        zIndex: "1",
      }}
      {...settings}
    >
      {children}
    </NukaCarousel>
  );
};

export default Carousel;
