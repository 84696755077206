import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import actions from "../actions";
import { RootState } from "../store";
import { ColorThemeState, ColorThemePayload } from "../types/colorTheme";
import { setTransitionActive, toggleLogoRotation } from "../actions/colorTheme";

const initialState: ColorThemeState = {
  current: "future",
  isLoading: true,
  error: undefined,
  isTransitionActive: false,
  logoRotaion: false,
};

export const colorThemeSlice = createSlice({
  name: "colorTheme",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      actions.colorTheme.updateColorTheme.fulfilled,
      (state, action: PayloadAction<ColorThemePayload>) => {
        state.current = action.payload;
        state.isLoading = false;
        state.error = undefined;
      },
    );
    builder.addCase(
      actions.colorTheme.updateColorTheme.rejected,
      (state, action) => {
        state.current = "future";
        state.isLoading = false;
        state.error = action.error.message;
      },
    );
    builder.addCase(
      setTransitionActive,
      (state, action: PayloadAction<boolean>) => {
        state.isTransitionActive = action.payload;
      },
    );
    builder.addCase(toggleLogoRotation, state => {
      state.logoRotaion = !state.logoRotaion;
    });
  },
});

export const searchSelector = (state: RootState) => state.colorTheme;

export default colorThemeSlice.reducer;
