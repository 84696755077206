import styled from "styled-components";
import { media } from "../../../../../styles/sizes";

export const QuestionWrap = styled.div`
  height: 100%;
  flex-grow: 1;
  h4,
  span {
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 590;
    font-size: 32px;
    line-height: 38px;
    color: #fff;
  }
  span {
    font-size: 20px;
  }
  @media screen and (${media.md}) {
    margin-top: 70px;
    h4,
    span {
      font-size: 32px;
      text-align: start;
    }
  }
`;

export const QuestionAnswers = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 35px;
`;
