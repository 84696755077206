import React from "react";

import { SvgIconProps, SvgObjectLinking } from "../types";

import { ReactComponent as DropDownArrow } from "../../../assets/icons/drop-down-arrow.svg";
import { ReactComponent as LogoSquare } from "../../../assets/icons/logo-square.svg";
import { ReactComponent as FolderTop } from "../../../assets/icons/folder-top.svg";
import { ReactComponent as ProfileFuture } from "../../../assets/icons/profile-future.svg";
import { ReactComponent as CaseFuture } from "../../../assets/icons/case-future.svg";
import { ReactComponent as ArrowFuture } from "../../../assets/icons/arrow-future.svg";
import { ReactComponent as ProfileCyberpunk } from "../../../assets/icons/profile-cyberpunk.svg";
import { ReactComponent as CaseCyberpunk } from "../../../assets/icons/case-cyberpunk.svg";
import { ReactComponent as ArrowCyberpunk } from "../../../assets/icons/arrow-cyberpunk.svg";
import { ReactComponent as DocFuture } from "../../../assets/icons/doc-future.svg";
import { ReactComponent as DocCyberpunk } from "../../../assets/icons/doc-cyberpunk.svg";
import { ReactComponent as PlusRounded } from "../../../assets/icons/plus-rounded.svg";
import { ReactComponent as PickerDoc } from "../../../assets/icons/picker-doc.svg";
import { ReactComponent as PickerDocCyber } from "../../../assets/icons/picker-doc-cyberpunk.svg";
import { ReactComponent as PickerSpiralArrow } from "../../../assets/icons/picker-spiral-arrow.svg";
import { ReactComponent as LockOn } from "../../../assets/icons/lock-on.svg";
import { ReactComponent as LockOff } from "../../../assets/icons/lock-off.svg";
import { ReactComponent as Copy } from "../../../assets/icons/copy.svg";
import { ReactComponent as BusinessCheckFuture } from "../../../assets/icons/business-check-future.svg";
import { ReactComponent as BusinessDocsFuture } from "../../../assets/icons/business-docs-future.svg";
import { ReactComponent as BusinessCameraFuture } from "../../../assets/icons/business-camera-future.svg";
import { ReactComponent as BusinessSyncFuture } from "../../../assets/icons/business-sync-future.svg";
import { ReactComponent as BusinessCheckCyberpunk } from "../../../assets/icons/business-check-cyberpunk.svg";
import { ReactComponent as BusinessDocsCyberpunk } from "../../../assets/icons/business-docs-cyberpunk.svg";
import { ReactComponent as BusinessCameraCyberpunk } from "../../../assets/icons/business-camera-cyberpunk.svg";
import { ReactComponent as BusinessSyncCyberpunk } from "../../../assets/icons/business-sync-cyberpunk.svg";
import { ReactComponent as ScrollDownButton } from "../../../assets/icons/scroll-down-button.svg";

const SvgIcon = ({ type, style, className, onClick }: SvgIconProps) => {
  const SVG_BY_TYPE: SvgObjectLinking = {
    dropDownArrow: DropDownArrow,
    logoSquare: LogoSquare,
    folderTop: FolderTop,
    profile_future: ProfileFuture,
    arrow_future: ArrowFuture,
    case_future: CaseFuture,
    profile_cyberpunk: ProfileCyberpunk,
    arrow_cyberpunk: ArrowCyberpunk,
    case_cyberpunk: CaseCyberpunk,
    docFuture: DocFuture,
    docCyberpunk: DocCyberpunk,
    plusRounded: PlusRounded,
    pickerDoc_future: PickerDoc,
    pickerDoc_cyberpunk: PickerDocCyber,
    pickerSpiralArrow: PickerSpiralArrow,
    lockOn: LockOn,
    lockOff: LockOff,
    copy: Copy,
    businessCheckFuture: BusinessCheckFuture,
    businessDocsFuture: BusinessDocsFuture,
    businessCameraFuture: BusinessCameraFuture,
    businessSyncFuture: BusinessSyncFuture,
    businessCheckCyberpunk: BusinessCheckCyberpunk,
    businessDocsCyberpunk: BusinessDocsCyberpunk,
    businessCameraCyberpunk: BusinessCameraCyberpunk,
    businessSyncCyberpunk: BusinessSyncCyberpunk,
    scrollDownButton: ScrollDownButton,
  };

  const Svg = SVG_BY_TYPE[type as string] || "svg";

  return <Svg style={style} className={className} onClick={onClick} />;
};

export default SvgIcon;
