interface ThemeButtonContent {
  future: string;
  cyberpunk: string;
}

type CurrentThemeLocalStorageKey = string;

export const THEME_BUTTON_CONTENT: ThemeButtonContent = {
  future: "Cyberpunk Theme",
  cyberpunk: "Future Theme",
};

export const CURRENT_COLOR_THEME: CurrentThemeLocalStorageKey =
  "CURRENT_COLOR_THEME";
