import React from "react";
import classNames from "classnames";

import { useAppSelector } from "../../../store/hooks";
import {
  ADVANTAGES_ITEMS,
  ADVANTAGES_TABS,
} from "../../../utils/constants/content";

import TabsCarousel from "../../shared/TabsCarousel";

import styles from "./index.module.scss";
import Background from "../Hero/molecules/Background";
import Card from "./molecules/Card/Card";

const Advantages = () => {
  const { current } = useAppSelector(state => state.colorTheme);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.content, styles[`content_${current}`])}>
        <Background />
        <TabsCarousel items={ADVANTAGES_TABS} />
      </div>

      <div className={styles.staticContent}>
        {Object.keys(ADVANTAGES_ITEMS).map(advantageKey => (
          <Card
            key={advantageKey}
            item={ADVANTAGES_ITEMS[advantageKey as string]}
          />
        ))}
      </div>
    </div>
  );
};

export default Advantages;
