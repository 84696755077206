import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../store/hooks";

import differentiation from "../../../assets/images/differentiation.png";
import differentiationCyber from "../../../assets/images/differentiation-cyber.png";

import styles from "./index.module.scss";

const Differentiation = () => {
  const { current } = useAppSelector(state => state.colorTheme);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={classNames(styles.content, styles[`content_${current}`])}>
        <div className={styles.text}>
          <p className={classNames(styles.title, styles[`title_${current}`])}>
            {t("Differentiation of ")}

            <span>{t("access rights")}</span>

            {t(" to files:")}
          </p>

          <p className={styles.description}>
            {t(
              "With reliable distributed infrastructure combined with transparent and controlled administration tools, your company’s data is securely stored",
            )}
          </p>
        </div>

        <img
          src={current === "future" ? differentiation : differentiationCyber}
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default Differentiation;
