import React from "react";
import classNames from "classnames";

import { InputProps } from "../types";

import SvgIcon from "../SvgIcon";
import Button from "../Button";

import styles from "./index.module.scss";
import { useAppSelector } from "../../../store/hooks";

const Input = ({
  value,
  placeholder,
  icon,
  button,
  disabled,
  className,
  onChange,
  onIconClick,
}: InputProps) => {
  const { current } = useAppSelector(state => state.colorTheme);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.wrapper}>
        <span
          className={classNames(styles.circle, styles[`circle_${current}`])}
        />
        <input
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          className={classNames(styles.field, {
            [styles.field_withButton]: button,
            [styles.field_withIcon]: icon,
          })}
          onChange={handleChange}
        />

        {icon && (
          <div className={styles.iconContainer}>
            <div className={styles.iconBg} />

            <SvgIcon
              type={icon}
              className={styles.icon}
              onClick={onIconClick}
            />
          </div>
        )}
      </div>
      {button && <Button {...button} className={styles.button} />}
    </div>
  );
};

export default Input;
