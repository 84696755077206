import { InputHTMLAttributes } from "react";
import styled, { css, keyframes } from "styled-components";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  $error?: boolean;
  $withicon?: boolean;
}

const autofill = keyframes`
  to {
    color: inherit;
    background: transparent;
  }
`;

export const AuthInputWrap = styled.div`
  position: relative;
  width: 100%;
`;

export const Circle = styled.span`
  background: #36faff;
  position: absolute;
  border-radius: 50%;
  left: 25%;
  top: 50%;
  width: 1%;
  padding-bottom: 1%;
  opacity: 0;
  transition: all 0.3s linear;
  z-index: 0;
`;

export const InputField = styled.input<InputFieldProps>`
  border-radius: 8px;
  border: 1px solid #747474;
  height: 50px;
  background: transparent;
  padding: 13px 18px 13px 18px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.004rem;
  color: #f5f5f5;
  width: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;

  &:focus {
    border: 1px solid #36faff;
  }

  &::placeholder {
    color: #888888;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.004rem;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: #f5f5f5;
    -webkit-box-shadow: 0 0 0px 40rem #0b0859 inset;
    caret-color: #fff;
    animation-name: ${autofill};
    animation-fill-mode: both;
  }

  ${props =>
    props.$error &&
    css`
      border: 1px solid #d72419;
    `}

  ${props =>
    props.$withicon &&
    css`
      padding-left: 50px;
    `}
`;

export const AnimationWrapper = styled.div`
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  &:hover {
    ${Circle} {
      transition: all 0.5s linear;
      opacity: 0.3;
      transform: scale(170);
    }
  }
`;

export const ErrorText = styled.span`
  position: absolute;
  left: 10px;
  bottom: -13px;
  font-weight: 510;
  font-size: 10px;
  line-height: 100%;
  color: #d72419;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  z-index: 2;
`;

export const EyeButton = styled.button`
  position: absolute;
  right: 15px;
  top: 50%;
  background: transparent;
  border: none;
  transform: translateY(-50%);
  svg {
    cursor: pointer;
    path {
      fill: #fff;
    }
  }
  z-index: 2;
`;
