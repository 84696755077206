import styled from "styled-components";
import { media } from "../../../styles/sizes";

export const Wrap = styled.div`
  height: 100%;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  h4 {
    font-weight: 590;
    font-size: 36px;
    line-height: 43px;
    color: #fff;
    margin-bottom: 15px;
  }

  @media screen and (${media.md}) {
    margin-top: 150px;
  }
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  margin-bottom: 30px;
`;

export const WorkspaceBox = styled.div`
  position: relative;
  input {
    padding-right: 88px;
  }
`;

export const WorkspaceText = styled.div`
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  width: max-content;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.4);
  padding: 14px 12px;
  font-size: 12px;
  color: #f6f6f6;
`;
