import axios from "axios";

import { ApiFactory, Request, ErrorType } from "./types";

export default class ApiClient {
  apiUrl: string;
  apiPrefix: string;

  constructor({ apiUrl, apiPrefix }: ApiFactory) {
    if (!apiUrl || !apiPrefix) {
      throw new Error("[apiUrl] and [apiPrefix] required");
    }

    this.apiUrl = apiUrl;
    this.apiPrefix = apiPrefix;
  }

  async get(url: string, params: object) {
    return this.request({
      url,
      method: "get",
      params,
    });
  }

  async post(url: string, payload: object) {
    return this.request({
      url,
      method: "post",
      payload,
    });
  }

  async request({ url, method, /* params = {}, */ payload = {} }: Request) {
    const requestUrl = `${this.apiUrl}/${this.apiPrefix}/${url}`;

    try {
      const response = await axios[method](requestUrl, payload);

      return response;
    } catch (error) {
      this.handleServerError(<ErrorType>error);
      console.error(error);

      throw error;
    }
  }

  handleServerError(error: ErrorType) {
    console.log("Server error: ", error);
  }
}
