import React, { useState } from "react";
import classNames from "classnames";

import { AdvantageProps } from "../../../types";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../store/hooks";

const Advantage = ({
  title,
  description,
  image,
  imageProps,
}: AdvantageProps) => {
  const { t } = useTranslation();
  const { current } = useAppSelector(state => state.colorTheme);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(
          styles.container,
          styles.container_front,
          isExpanded && styles.container_front_isExpanded,
          styles[`container_${current}`],
        )}
      >
        <div
          className={classNames(styles.circle, showAnimation && styles.active)}
        />
        <div className={classNames(styles.frame, styles[`frame_${current}`])} />
        <p className={classNames(styles.title, styles[`title_${current}`])}>
          {t(title)}
        </p>
        <div className={styles.collapsibleContent}>
          <img {...imageProps} src={image} className={styles.image} />
        </div>
        <div
          onMouseEnter={() => setShowAnimation(true)}
          onMouseLeave={() => setShowAnimation(false)}
          onClick={() => setIsExpanded(!isExpanded)}
          className={styles.collapseIconContainer}
        >
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
        </div>
      </div>
      <div
        className={classNames(
          styles.container,
          styles.container_back,
          isExpanded && styles.container_back_isExpanded,
          styles[`container_${current}`],
        )}
      >
        <div
          className={classNames(styles.circle, showAnimation && styles.active)}
        />
        <div className={classNames(styles.frame, styles[`frame_${current}`])} />
        <p className={classNames(styles.title, styles[`title_${current}`])}>
          {t(title)}
        </p>
        <div className={styles.collapsibleContent}>
          <p className={styles.description}>{t(description)}</p>
        </div>
        <div
          onMouseEnter={() => setShowAnimation(true)}
          onMouseLeave={() => setShowAnimation(false)}
          onClick={() => setIsExpanded(!isExpanded)}
          className={styles.collapseIconContainer}
        >
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
        </div>
      </div>
    </div>
  );
};

export default Advantage;
