// import { mixinBreakpoints } from "@styles/mixins/helpers";
import { media } from "../../../styles/sizes";
import { ButtonHTMLAttributes } from "react";
import styled, { css, keyframes } from "styled-components";

import { ReactComponent as CheckIcon } from "../../../assets/icons/checkmark_2.svg";

interface StyledAuthButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  $isActive?: boolean;
  $signUpButton?: boolean;
  $confirmButton?: boolean;
  $questionnaireButton?: boolean;
  $answerButton?: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const Circle = styled.span`
  background: #36faff;
  position: absolute;
  border-radius: 50%;
  left: 10%;
  top: 10%;
  width: 2%;
  padding-bottom: 2%;
  opacity: 0;
  transition: all 0.3s linear;
  z-index: 0;
`;

export const Icon = styled(CheckIcon)`
  position: absolute;
  right: 11px;
  top: 8px;
  z-index: 1;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-in-out forwards;
`;

export const StyledAuthButton = styled.button<StyledAuthButtonProps>`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 15px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  border: 1px solid transparent;
  overflow: hidden;
  cursor: pointer;

  @media screen and (${media.md}) {
    font-size: 16px;
  }

  & > div {
    position: relative;
    z-index: 1;
  }

  ${props =>
    props.$signUpButton &&
    css`
      width: 100%;
      max-width: 450px;
      margin-top: 15px;

      & > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        & > img {
          width: auto;
        }
      }
    `}

  ${props =>
    props.$confirmButton &&
    css`
      width: 100%;
      background: #36faff;
      color: #111;
      margin-top: 45px;
      font-weight: 650;
      & > ${Circle} {
        background: #0a3aac;
      }
    `}

  ${props =>
    props.$questionnaireButton &&
    css`
      background: #36faff;
      color: #111;
      font-weight: 650;
      padding: 14px 33px;
      & > ${Circle} {
        background: #0a3aac;
      }
    `}

  ${props =>
    props.$answerButton &&
    css`
      height: 100px;
      width: calc((100% / 5) - 12px);
      opacity: 0.8;
      transition: all 0.2s ease-in-out;
      @media screen and (${media.md}) {
        width: 30%;
      }
    `}  

  &:hover {
    ${Circle} {
      transition: all 0.5s linear;
      opacity: 0.5;
      transform: scale(140);
    }
  }

  &:disabled {
    opacity: 0.5;
    ${Circle} {
      display: none;
    }
  }

  &:focus {
    outline: none;
  }

  ${props =>
    props.$isActive &&
    css`
      border: 1px solid #36faff;
    `}
`;
