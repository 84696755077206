import {
  AdvantagesItem,
  AdvantagesTab,
  BusinessSliderItems,
  FooterLink,
  HeroFolders,
  PersonalAdvantages,
  Tariffs,
  UploadFileAdvantages,
  UploadFileRadioGroup,
} from "./types";

import uploadFileAdvantageFolders from "../../assets/images/upload-file-advantage-folders.png";
import uploadFileAdvantageFoldersCyber from "../../assets/images/upload-file-advantage-folders_cyberpunk.png";
import uploadFileAdvantageLaptop from "../../assets/images/upload-file-advantage-laptop.png";
import uploadFileAdvantageLaptopCyber from "../../assets/images/upload-file-advantage-laptop_cyber.png";
import personalAdvantageFilesDesktop from "../../assets/images/personal-advantage-files-desktop.png";
import personalAdvantageFilesDesktopCyber from "../../assets/images/personal-advantage-files-desktop_cyber.png";
import personalAdvantageTimeDesktop from "../../assets/images/personal-advantage-time-desktop.png";
import personalAdvantageTimeDesktopCyber from "../../assets/images/personal-advantage-time-desktop-cyber.png";
import personalAdvantageSecurityDesktop from "../../assets/images/personal-advantage-security-desktop.png";
import personalAdvantageSecurityDesktopCyber from "../../assets/images/personal-advantage-security-desktop_cyber.png";
import advantagesServices from "../../assets/images/advantages-services.png";
import advantagesServicesCyber from "../../assets/images/advantages-services_cyber.png";
import advantagesChat from "../../assets/images/advantages-chat.png";
import advantagesCalendar from "../../assets/images/advantages-calendar.png";
import advantagesChatCyber from "../../assets/images/advantages-chat_cyber.png";
import advantagesCalendarCyber from "../../assets/images/advantages-calendar_cyber.png";
import advantageSafety from "../../assets/images/advantage-safety.png";
import advantageSafetyCyber from "../../assets/images/advantage-safety_cyber.png";
import advantageData from "../../assets/images/advantage-data.png";
import advantageDataCyber from "../../assets/images/advantage-data_cyber.png";
import advantageSafe from "../../assets/images/advantage-safe.png";
import advantageSafeCyber from "../../assets/images/advantage-safe_cyber.png";

export const HERO_FOLDERS: HeroFolders = {
  personal: {
    targetId: "personal",
    topIcon: "profile",
    bottomIcon: "arrow",
    title: "Personal",
    description:
      "Even easier to use. Stay productive wherever you are with unrestricted access to all your files and applications.",
    frontColor: {
      future: "#ffffff",
      cyberpunk: "#0512FF",
    },
  },
  business: {
    targetId: "business",
    topIcon: "case",
    bottomIcon: "arrow",
    title: "Business",
    description:
      "All work in one virtual space, replaces a large number of different resources and applications. Communicate, organize your time and task managers.",
    frontColor: {
      future: "#00C6CC",
      cyberpunk: "#D20FFF",
    },
  },
};

export const UPLOAD_FILE_RADIO_GROUP: UploadFileRadioGroup[] = [
  { value: "7", label: "7 day" },
  { value: "14", label: "14 day" },
];

export const UPLOAD_FILE_ADVANTAGES: UploadFileAdvantages = {
  0: {
    title: "Data systematization in a common space",
    description:
      "Data protection, including in case of an emergency. Connect multi-stage authentication, connect multi-stage authentication.",
    image: {
      future: uploadFileAdvantageFolders,
      cyberpunk: uploadFileAdvantageFoldersCyber,
    },
    imageProps: {
      style: { width: "100%" },
    },
    textColor: {
      future: "#FFFFFF",
      cyberpunk: "#FFFFFF",
    },
    backgroundColor: {
      future: "#292929",
      cyberpunk: "#D509AA",
    },
  },
  1: {
    title: "Providing access for downloaded files",
    description:
      "This is a fast and secure way to transfer any large project files to your colleagues and customers. Set passwords and expiration dates to protect your work data and confirm delivery with download notifications.",
    image: {
      future: uploadFileAdvantageLaptop,
      cyberpunk: uploadFileAdvantageLaptopCyber,
    },
    imageProps: {
      style: { width: "68%" },
    },
    textColor: {
      future: "#FFFFFF",
      cyberpunk: "#000000",
    },
    backgroundColor: {
      future: "#292929",
      cyberpunk: "#3CF9FF",
    },
  },
};

export const BUSINESS_SLIDER_ITEMS: BusinessSliderItems = {
  0: {
    title: "Task board",
    description:
      "Board with all your tasks. Add tasks, track progress, mark completion and set deadlines.",
    icon: {
      future: "businessCheckFuture",
      cyberpunk: "businessCheckCyberpunk",
    },
    backgroundColor: { future: "1b1b1b", cyberpunk: "740fff" },
  },
  1: {
    title: "Work with files",
    description:
      "Control and organize files. Download, customize, filter for easy search and share with other employees. You can add an additional password to each file.",
    icon: { future: "businessDocsFuture", cyberpunk: "businessDocsCyberpunk" },
    backgroundColor: { future: "2b2b2b", cyberpunk: "2f0f9e" },
  },
  2: {
    title: "Simple communication",
    description:
      "Thanks to the built-in chat you can communicate with employees, call and arrange conferences. And all this without going to another application",
    icon: {
      future: "businessCameraFuture",
      cyberpunk: "businessCameraCyberpunk",
    },
    backgroundColor: { future: "494949", cyberpunk: "4627ff" },
  },
  3: {
    title: "Sync project collaboration with built-in tools",
    description:
      "Connect the right tools to the space and stay there. Do not waste time to exit, everything remains on the platform",
    icon: { future: "businessSyncFuture", cyberpunk: "businessSyncCyberpunk" },
    backgroundColor: { future: "7e7e7e", cyberpunk: "d010ff" },
  },
};

export const PERSONAL_ADVANTAGES: PersonalAdvantages = {
  0: {
    title: "Сontrol of files",
    description:
      "Control and organize your files. Divide into groups and custom.No more confusion. Convenient filament by tags, title and even emotions.",
    imageMobile: {
      future: personalAdvantageFilesDesktop,
      cyberpunk: personalAdvantageFilesDesktopCyber,
    },
    imageDesktop: {
      future: personalAdvantageFilesDesktop,
      cyberpunk: personalAdvantageFilesDesktopCyber,
    },
    backgroundColor: {
      future: "#2B2B2B",
      cyberpunk: "#2F0F9E",
    },
  },
  1: {
    title: "Time optimization",
    description:
      "Optimize your time with the built-in calendar. Mail, calls and even calendar tasks.",
    imageMobile: {
      future: personalAdvantageTimeDesktop,
      cyberpunk: personalAdvantageTimeDesktopCyber,
    },
    imageDesktop: {
      future: personalAdvantageTimeDesktop,
      cyberpunk: personalAdvantageTimeDesktopCyber,
    },
    backgroundColor: {
      future: "#494949",
      cyberpunk: "#CA10FF",
    },
  },
  2: {
    title: "Security",
    description:
      'All your files are secure. Additional password or "safe" protection. Safely share files with people and control changes to the file.',
    imageMobile: {
      future: personalAdvantageSecurityDesktop,
      cyberpunk: personalAdvantageSecurityDesktopCyber,
    },
    imageDesktop: {
      future: personalAdvantageSecurityDesktop,
      cyberpunk: personalAdvantageSecurityDesktopCyber,
    },
    backgroundColor: {
      future: "#7E7E7E",
      cyberpunk: "#740FFF",
    },
  },
};

export const ADVANTAGES_TABS: AdvantagesTab[] = [
  {
    tab: "Services",
    title: "Integration of necessary services",
    description:
      "Synchronization with outside services. Built-in software store",
    image: {
      future: advantagesServices,
      cyberpunk: advantagesServicesCyber,
    },
  },
  {
    tab: "Chat",
    title: "Built-in chat",
    description:
      "No more switching to interact with employees. Communicate directly on the platform, arrange conferences and video calls.",
    image: {
      future: advantagesChat,
      cyberpunk: advantagesChatCyber,
    },
  },
  {
    tab: "Calendar",
    title: "Your personal calendar",
    description:
      "Organize your time with a built-in calendar. Assign calls, meetings, tasks and even emails.",
    image: {
      future: advantagesCalendar,
      cyberpunk: advantagesCalendarCyber,
    },
  },
];

export const ADVANTAGES_ITEMS: AdvantagesItem = {
  0: {
    title: "Confidentiality and safety",
    description:
      "The security of your files is the most important thing for us. Therefore, we have implemented multi-level ways to protect your data.",
    image: {
      future: advantageSafety,
      cyberpunk: advantageSafetyCyber,
    },
  },
  1: {
    title: "Reliable protection of personal and corporate data",
    description:
      "You choose the level of protection of your files and can additionally set the password to the file or to the whole folder.",
    image: {
      future: advantageData,
      cyberpunk: advantageDataCyber,
    },
  },
  2: {
    title: 'Function "Safe"',
    description:
      'With the "Safe" feature you will be able to restrict access to files or folders and set additional protection. Each time you view a file, a new password will be sent to your number for access.',
    image: {
      future: advantageSafe,
      cyberpunk: advantageSafeCyber,
    },
  },
};

export const TARIFFS: Tariffs = {
  0: {
    name: "Free",
    price: 0,
    monthlyDescription: "Per user monthly starting from 3 users.",
    yearlyDescription: "Per user yearly starting from 3 users.",
    advantages: [
      "2 000 GB of secure file storage",
      "Simple tools for storing sharing and collaborating",
    ],
    isCurrentPlan: true,
    isPrimary: false,
  },
  1: {
    name: "Standart",
    price: 30,
    monthlyDescription: "Per user monthly starting from 3 users.",
    yearlyDescription: "Per user yearly starting from 3 users.",
    advantages: [
      "2 000 GB of secure file storage",
      "Simple tools for storing sharing and collaborating",
    ],
    isCurrentPlan: false,
    isPrimary: false,
  },
  2: {
    title: "Deal of the day",
    name: "Premium",
    oldPrice: 100,
    price: 50,
    monthlyDescription: "Per user monthly starting from 3 users.",
    yearlyDescription: "Per user yearly starting from 3 users.",
    advantages: [
      "2 000 GB of secure file storage",
      "Simple tools for storing sharing and collaborating",
    ],
    isCurrentPlan: false,
    isPrimary: true,
  },
  3: {
    name: "Premium plus",
    price: 120,
    monthlyDescription: "Per user monthly starting from 3 users.",
    yearlyDescription: "Per user yearly starting from 3 users.",
    advantages: [
      "2 000 GB of secure file storage",
      "Simple tools for storing sharing and collaborating",
    ],
    isCurrentPlan: false,
    isPrimary: false,
  },
};

export const FOOTER_LINKS: FooterLink[] = [
  { label: "Tariff plan", path: "#tariff-plan" },
  { label: "Safety", path: "#safety" },
  { label: "Functions", path: "#functions" },
  { label: "About 4Hub", path: "#about" },
  { label: "Vacansies", path: "#vacansies" },
  { label: "Blog", path: "#blog" },
  { label: "Contacts", path: "#contacts" },
  { label: "Site map", path: "#site-map" },
  { label: "Confidentiality", path: "#confidentiality" },
  { label: "Developers", path: "#developers" },
  { label: "Invite friends", path: "#invite" },
  { label: "Support service", path: "#support" },
];
