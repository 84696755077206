import React, { useState } from "react";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useAppSelector } from "../../../../../store/hooks";

const Card = ({
  item,
}: {
  item: {
    title: string;
    description: string;
    image: { [key: string]: string };
  };
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { title, description, image } = item;
  const { current } = useAppSelector(state => state.colorTheme);
  const { t } = useTranslation();
  const [showAnimation, setShowAnimation] = useState(false);

  return (
    <div
      key={title}
      className={classNames(styles.advantage, styles[`advantage_${current}`])}
    >
      <div
        className={classNames(
          styles.container,
          styles.container_front,
          isExpanded && styles.container_front_isExpanded,
          styles[`container_${current}`],
        )}
      >
        <div
          className={classNames(styles.circle, showAnimation && styles.active)}
        />
        <div className={styles.text}>
          <p className={styles.title}>{t(title)}</p>

          <p className={styles.description}>{t(description)}</p>
        </div>

        <div className={styles.imageContainer}>
          <img src={image[current]} />
        </div>
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          onMouseEnter={() => setShowAnimation(true)}
          onMouseLeave={() => setShowAnimation(false)}
          className={styles.collapseIconContainer}
        >
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
        </div>
      </div>
      <div
        className={classNames(
          styles.container,
          styles.container_back,
          isExpanded && styles.container_back_isExpanded,
          styles[`container_${current}`],
        )}
      >
        <div
          className={classNames(styles.circle, showAnimation && styles.active)}
        />
        <div className={styles.text}>
          <p className={styles.title}>{t(title)}</p>

          <p className={styles.description}>{t(description)}</p>
        </div>

        <div
          onClick={() => setIsExpanded(!isExpanded)}
          onMouseEnter={() => setShowAnimation(true)}
          onMouseLeave={() => setShowAnimation(false)}
          className={styles.collapseIconContainer}
        >
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
        </div>
      </div>
    </div>
  );
};

export default Card;
