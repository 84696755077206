import apiFactory from "./api";
import { ApiFactory } from "./api/types";

const api = apiFactory(<ApiFactory>{
  apiUrl: process.env.REACT_APP_API_URL,
  apiPrefix: process.env.REACT_APP_API_PREFIX,
  onError: error => console.log("Connection error: ", error.message),
});

export default api;
