export enum Device {
  MINI = 744,
  TABLET = 834,
  LAPTOP = 1280,
  DESKTOP = 1440,
  WIDE = 1512,
}

export const device = {
  XS: `${Device.MINI}px`,
  SM: `${Device.TABLET}px`,
  MD: `${Device.LAPTOP}px`,
  LG: `${Device.DESKTOP}px`,
  XL: `${Device.WIDE}px`,
};

export const media = {
  xs: `min-width: ${device.XS}`,
  sm: `min-width: ${device.SM}`,
  md: `min-width: ${device.MD}`,
  lg: `min-width: ${device.LG}`,
  xl: `min-width: ${device.XL}`,
};
