import { ApiClientInterface } from "./types";

class Base {
  apiClient: ApiClientInterface;

  constructor(props: { apiClient: ApiClientInterface }) {
    if (!props.apiClient) {
      throw new Error("[apiClient] required");
    }

    this.apiClient = props.apiClient;
  }
}

export default Base;
