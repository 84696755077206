import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { InternalLinkProps } from "../types";

import styles from "./index.module.scss";

const InternalLink = ({ to, text, replace, className }: InternalLinkProps) => {
  const { t } = useTranslation();

  return (
    <Link
      to={to}
      replace={replace}
      className={classNames(styles.link, className)}
    >
      {t(text)}
    </Link>
  );
};

export default InternalLink;
