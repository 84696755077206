import React from "react";
import { useTranslation } from "react-i18next";
import ReactCurvedText from "react-curved-text";

import styles from "./index.module.scss";
import SvgIcon from "../../../../shared/SvgIcon";

const DownButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.preview} />
      <div className={styles.icon}>
        <SvgIcon type="scrollDownButton" />
      </div>
      <div className={styles.textContainer}>
        <ReactCurvedText
          width={100}
          height={100}
          cx={50}
          cy={50}
          rx={50}
          ry={50}
          startOffset={0}
          reversed={true}
          svgProps={{
            className: styles.text,
          }}
          text={t("Download file")}
        />
      </div>
    </div>
  );
};

export default DownButton;
