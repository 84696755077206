import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { ToastContainerProps } from "../types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import actions from "../../../store/actions";

import styles from "./index.module.scss";

const ToastContainer = ({ children }: ToastContainerProps) => {
  const { t } = useTranslation();
  const { message, type } = useAppSelector(state => state.toast);
  const dispatch = useAppDispatch();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (message) {
      setIsActive(true);

      setTimeout(() => {
        setIsActive(false);
      }, 3000);

      setTimeout(() => {
        dispatch(actions.toast.hideToast());
      }, 4000);
    }
  }, [message]);

  return (
    <div className={styles.container}>
      {children}

      <div
        className={classNames(styles.toast, {
          [styles.toast_active]: isActive,
          [styles.error]: type === "error",
        })}
      >
        <p>{t(message)}</p>
      </div>
    </div>
  );
};

export default ToastContainer;
