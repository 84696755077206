import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook.svg";
import { ReactComponent as AppleIcon } from "../../../assets/icons/apple.svg";
import constants from "../../../utils/constants";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ToastContainer from "../../containers/ToastContainer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  ButtonsWrapper,
  InputsWrapper,
  OrText,
  SuccessText,
  Wrap,
  WorkspaceBox,
  WorkspaceText,
  Subtitle,
} from "./SignUp.styled";
import {
  getGmt,
  getTimezoneIp,
  getUserDateFormat,
} from "../../../utils/functions";
import i18next, { t } from "i18next";
import { _showToast } from "../../../store/reducers/toast";
import api from "../../../apiSingleton";
import { isEmail } from "../../../utils/validation/emailValidation";
import { useAppDispatch } from "../../../store/hooks";
import { GoogleButton } from "../../shared/GoogleButton/GoogleButton";
import { AuthButton } from "../../shared/AuthButton";
import { AuthInput } from "../../shared/AuthInput";
import { AxiosError } from "axios";

interface IErrors {
  workspace: string;
  email: string;
  password: string;
}

const regexWorkSpace = /^[a-z0-9]+$/;

const SignUp = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const answers = location.state?.answers;
  const dispatch = useAppDispatch();
  const [isPending, setIsPending] = useState<boolean>(false);

  const [isSend, setIsSend] = useState<boolean>(false);
  const [googleData, setGoogleData] = useState<string>("");
  const [workspace, setWorkspace] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<IErrors>({
    workspace: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    if (!answers) {
      // navigate(`/${ROUTES.QUESTIONNAIRE}`);
      navigate(`/${constants.routes.QUESTIONARE}`);
    }
  }, [answers]);

  // const dispatch = useAppDispatch();

  const handleWorkspaceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLocaleLowerCase();
    setWorkspace(value);
    setErrors({ ...errors, workspace: "" });
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
    setErrors({ ...errors, email: "" });
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
    setErrors({ ...errors, password: "" });
  };

  const onValidation = (): boolean => {
    if (
      !isEmail(email) ||
      !password ||
      password.length < 8 ||
      !workspace ||
      regexWorkSpace.test(workspace)
    ) {
      const errors: IErrors = {
        workspace: "",
        email: "",
        password: "",
      };
      if (!isEmail(email)) errors.email = t("inputErrors.invalid_email");
      if (!password) errors.password = t("inputErrors.required");
      if (password.length < 8)
        errors.password = t("inputErrors.min_characters", { quantity: 8 });
      if (!workspace) errors.workspace = t("inputErrors.required");
      if (!regexWorkSpace.test(workspace))
        errors.workspace = t("inputErrors.a_z");

      setErrors(errors);
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (onValidation() && !googleData) return;
    const response = await getTimezoneIp();
    const formData = new FormData();
    formData.append("domain", `${workspace}.mh.net.ua`);
    password && formData.append("pass", password);
    email && formData.append("email", email);
    formData.append("gmt", getGmt());
    formData.append("is_gmt_auto", "true");
    formData.append("timeZone", response.data.timezone);
    formData.append(
      "lang",
      localStorage.getItem("APP_lLANG") || i18next.language,
    );
    formData.append("dateFormat", getUserDateFormat());
    googleData && formData.append("google", googleData);

    try {
      setIsPending(true);
      const { data } = await api.reg.send(formData);
      if (googleData) {
        console.log("token", data.token);
        window.location.href = `https://${workspace}.mh.net.ua/registration/${data.token}`;
      } else {
        setIsSend(true);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          _showToast({
            message: t(`errors.${error?.response?.data?.error_code}`),
            type: "error",
          }),
        );
      }
    } finally {
      setIsPending(false);
    }
  };

  const onGoogleAuth = (data: string) => {
    setGoogleData(data);
    if (workspace) {
      onSubmit();
    }
  };

  return (
    <GoogleOAuthProvider
      clientId={
        "824792284729-d3mkpobr143jr8s76qjh8a3ueav95pfq.apps.googleusercontent.com"
      }
    >
      <ToastContainer>
        <Wrap>
          <h4>{t("auth.signUpTitle")}</h4>
          {isSend ? (
            <SuccessText
              dangerouslySetInnerHTML={{ __html: t("auth.signupText") }}
            />
          ) : (
            <>
              <Subtitle>{t("auth.signupSubtitle")}</Subtitle>
              {!googleData && (
                <ButtonsWrapper>
                  <GoogleButton
                    setError={() => console.log("first")}
                    setGoogleData={onGoogleAuth}
                  />
                  <AuthButton>
                    <FacebookIcon />
                  </AuthButton>
                  <AuthButton>
                    <AppleIcon fill="#fff" />
                  </AuthButton>
                </ButtonsWrapper>
              )}
              {!googleData && <OrText>{t("short.or")}</OrText>}
              <InputsWrapper>
                <WorkspaceBox>
                  <AuthInput
                    value={workspace}
                    onChange={handleWorkspaceChange}
                    placeholder={t("short.workspace")}
                    error={errors.workspace}
                  />
                  <WorkspaceText>.4Hub.com</WorkspaceText>
                </WorkspaceBox>
                {!googleData && (
                  <>
                    <AuthInput
                      value={email}
                      onChange={handleEmailChange}
                      type="email"
                      placeholder="Email"
                      error={errors.email}
                    />
                    <AuthInput
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder={t("short.password")}
                      type="password"
                      error={errors.password}
                    />
                  </>
                )}
              </InputsWrapper>
              <AuthButton
                disabled={isPending || !workspace}
                confirmButton
                onClick={onSubmit}
              >
                {t("short.continue")}
              </AuthButton>
            </>
          )}
        </Wrap>
      </ToastContainer>
    </GoogleOAuthProvider>
  );
};

export default SignUp;
