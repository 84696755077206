import React from "react";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";

import { RadioGroupProps } from "../types";
import { useAppSelector } from "../../../store/hooks";

import styles from "./index.module.scss";

const RadioGroup = ({ value, items, onChange }: RadioGroupProps) => {
  const { t } = useTranslation();
  const { current } = useAppSelector(state => state.colorTheme);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target.value as string;

    onChange(target);
  };

  return (
    <form className={styles.container}>
      {items.map(el => {
        const id = nanoid();
        const { label, value: itemValue } = el;

        return (
          <div key={itemValue} className={styles.groupItem}>
            <input
              type="radio"
              id={id}
              value={itemValue}
              checked={itemValue === value}
              className={classNames(styles.input, styles[`input_${current}`])}
              onChange={handleChange}
            />

            <label htmlFor={id}>{t(label)}</label>
          </div>
        );
      })}
    </form>
  );
};

export default RadioGroup;
