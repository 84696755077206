import { media } from "../../../styles/sizes";
import styled, { keyframes } from "styled-components";
import { SwiperSlide } from "swiper/react";

const opacityChange = keyframes`
    0%, 100% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }
`;

const rotate = keyframes`
    100% {
        transform: rotate(1turn);
    }
`;

export const AuthSlideTitle = styled.h4`
  font-weight: 590;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.432px;
  color: #fff;
  width: 100%;
  margin-left: 62px;
  position: relative;
  z-index: 1;
`;

export const AuthSlideText = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  margin: 10px 10px 10px 62px;
  position: relative;
  z-index: 1;
`;

export const AuthSliderImage = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
`;

export const AuthSliderSlidePagination = styled.div`
  background: linear-gradient(112.48deg, #efefef 6.31%, #ababab 98.51%);
  border-radius: 50px;
  position: absolute;
  z-index: 1;
  width: fit-content;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%);
  padding: 0 8px;
  height: 24px;

  div {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 100%;

    span {
      background: #9faeab;
      width: 8px;
      height: 8px;
      margin: 0 !important;
    }
  }
`;

export const AuthSliderFrame = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 48px;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-repeat: no-repeat;
    background-size:
      50% 50%,
      50% 50%;
    background-position:
      0 0,
      100% 0,
      100% 100%,
      0 100%;
    background-color: #23216a;
    background-image: linear-gradient(#36faff, #23216a);
    animation:
      ${rotate} 4s linear infinite,
      ${opacityChange} 3s ease-in-out infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 48px;
    background-color: #23216a;
    transition: all 0.5s ease-in-out;
  }
`;

export const CustomSwiperSlide = styled(SwiperSlide)`
  display: flex;

  height: 338px;
  padding-top: 56px;
  background: #23216a;
  border-radius: 48px;
  overflow: hidden;
  width: 100%;
  @media screen and (${media.md}) {
    flex-direction: column;
    width: 55%;
    height: 100%;
  }
`;
