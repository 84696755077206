import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { scrollTo } from "seamless-scroll-polyfill";

import { ReactComponent as LogoFull } from "../../../assets/icons/logo-full.svg";
import useCurrentLanguage from "../../../hooks/useCurrentLanguage";
import { useAppSelector } from "../../../store/hooks";
import constants from "../../../utils/constants";
import Folder from "./molecules/Folder";

import styles from "./index.module.scss";
import Background from "./molecules/Background";
import DownButton from "./molecules/DownButton";

const Hero = () => {
  const { current, logoRotaion } = useAppSelector(state => state.colorTheme);
  const currentLang = useCurrentLanguage();
  const { t } = useTranslation();

  const handleScrollDown = () => {
    scrollTo(window, { top: window.innerHeight, behavior: "smooth" });
  };

  return (
    <div className={styles.container}>
      <Background />
      <DownButton onClick={handleScrollDown} />
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <LogoFull
            className={classNames(
              styles.logo,
              logoRotaion && styles.logo_animation,
            )}
          />
          <p
            className={classNames(styles.title, styles[`title_${currentLang}`])}
          >
            {t("Smart workspace")}
          </p>
        </div>

        <div className={styles.folders}>
          {Object.keys(constants.content.HERO_FOLDERS).map(fold => {
            const {
              targetId,
              topIcon,
              bottomIcon,
              title,
              description,
              frontColor,
            } =
              constants.content.HERO_FOLDERS[
                fold as keyof typeof constants.content.HERO_FOLDERS
              ];

            return (
              <Folder
                key={title}
                targetId={targetId}
                topIcon={`${topIcon}_${current}`}
                bottomIcon={`${bottomIcon}_${current}`}
                title={t(title)}
                description={t(description)}
                frontColor={frontColor[current]}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Hero;
