import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { DropDownProps } from "../types";

import SvgIcon from "../SvgIcon";

import styles from "./index.module.scss";
import { useAppSelector } from "../../../store/hooks";

const Select = ({ value, options, onChange }: DropDownProps) => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector(state => state.colorTheme.current);
  const [isExpanded, setIsExpanded] = useState(false);

  const changeExpanded = () => setIsExpanded(prev => !prev);

  const handleChange = (value: string) => {
    onChange(value);
    changeExpanded();
  };

  return (
    <div className={styles.container}>
      <div className={styles.valueContainer} onClick={changeExpanded}>
        <span>{t(value)}</span>

        <SvgIcon type="dropDownArrow" />
      </div>

      <div
        className={classNames(
          styles.options,
          styles[`options_${currentTheme}`],
          {
            [styles.options_expanded]: isExpanded,
          },
        )}
      >
        {options.map(option => {
          const { value: optionValue, label } = option;

          return (
            <span
              key={optionValue}
              onClick={() => handleChange(optionValue)}
              className={classNames(styles.option, {
                [styles.option_active]: optionValue === value,
              })}
            >
              {t(label)}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default Select;
