import axios from "axios";

export const generateUniqueId = () => {
  const array = new Uint32Array(2);
  crypto.getRandomValues(array);
  const timestamp = new Date().getTime();
  const randomNumber = array[0] % 10000;
  return `${timestamp}-${randomNumber}`;
};

export const getGmt = () =>
  String((new Date().getTimezoneOffset() / -60).toFixed(1));

export const getTimezoneIp = () => {
  const response = axios("https://worldtimeapi.org/api/ip");
  return response;
};

export const checkTimeFormat = (): string => {
  const dateFormatter = new Intl.DateTimeFormat("default", {
    timeStyle: "short",
  });
  const timeParts = dateFormatter.formatToParts(new Date());

  const is12HourFormat = timeParts.some(part => part.type === "dayPeriod");

  if (is12HourFormat) {
    return "hh:mm a";
  } else {
    return "HH:mm";
  }
};

export const checkDateFormat = (): string => {
  let dateFormat = "dd.MM.yyyy";
  if (typeof Intl.DateTimeFormat.prototype.formatToParts !== "function") {
    return dateFormat;
  }
  const dateFormatter = new Intl.DateTimeFormat("default");
  const dateParts = dateFormatter.formatToParts(new Date());
  const parts = dateParts.map(part => part.type);
  const separator = dateParts.find(el => el.type === "literal")?.value;
  if (!separator) {
    return dateFormat;
  }
  // Определяем формат даты, исходя из порядка частей
  if (
    parts.indexOf("day") < parts.indexOf("month") &&
    parts.indexOf("month") < parts.indexOf("year")
  ) {
    dateFormat = `dd${separator}MM${separator}yyyy`;
  } else if (
    parts.indexOf("month") < parts.indexOf("day") &&
    parts.indexOf("day") < parts.indexOf("year")
  ) {
    dateFormat = `MM${separator}dd${separator}yyyy`;
  } else if (
    parts.indexOf("year") < parts.indexOf("month") &&
    parts.indexOf("month") < parts.indexOf("day")
  ) {
    dateFormat = `yyyy${separator}MM${separator}dd`;
  }

  return dateFormat;
};
export const getUserDateFormat = (): string => {
  return `${checkDateFormat()}, ${checkTimeFormat()}`;
};
