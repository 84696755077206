import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { TARIFFS } from "../../../utils/constants/content";
import { useAppSelector } from "../../../store/hooks";

import Switch from "../../shared/Switch";
import Button from "../../shared/Button";

import styles from "./index.module.scss";

const TariffPlans = () => {
  const { t } = useTranslation();
  const { current } = useAppSelector(state => state.colorTheme);

  const [isYearly, setIsYearly] = useState(false);

  const handleSwitchChange = (checked: boolean) => setIsYearly(checked);

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t("Ready to start with 4HUB?")}</p>
      <div className={styles.switch}>
        <Switch
          checked={isYearly}
          text={{ left: t("monthly"), right: t("yearly") }}
          onChange={handleSwitchChange}
        />
      </div>
      <div className={styles.tariffs}>
        {Object.keys(TARIFFS).map(tariffKey => {
          const {
            isCurrentPlan,
            isPrimary,
            title,
            name,
            oldPrice,
            price,
            monthlyDescription,
            yearlyDescription,
            advantages,
          } = TARIFFS[tariffKey];

          const buttonLabel = isCurrentPlan ? "Current plan" : "Buy";

          return (
            <div
              key={name}
              className={classNames(
                styles.tariff,
                styles[`tariff_${current}`],
                {
                  [styles.tariff_disabled]: isCurrentPlan,
                  [styles.tariff_primary]: isPrimary,
                  [styles[`tariff_primary_${current}`]]: isPrimary,
                },
              )}
            >
              {title && <p className={styles.tariffTitle}>{t(title)}</p>}

              <p className={styles.tariffName}>{t(name)}</p>

              <div className={styles.priceContainer}>
                {oldPrice && (
                  <p className={styles.oldPrice}>{`$${
                    isYearly ? oldPrice * 12 : oldPrice
                  }`}</p>
                )}

                <p className={styles.price}>{`$${
                  isYearly ? price * 12 : price
                }`}</p>
              </div>

              <p
                className={classNames(styles.description, {
                  [styles.description_disabled]: isCurrentPlan,
                })}
              >
                {t(isYearly ? yearlyDescription : monthlyDescription)}
              </p>

              <ul
                className={classNames(
                  styles.advantages,
                  styles[`advantages_${current}`],
                  {
                    [styles.advantages_disabled]: isCurrentPlan,
                    [styles[`advantages_primary_${current}`]]: isPrimary,
                    [styles.advantages_primary]: isPrimary,
                  },
                )}
              >
                {advantages.map(advantage => {
                  return <li key={advantage}>{t(advantage)}</li>;
                })}
              </ul>

              <div className={styles.buttonContainer}>
                <Button
                  className={classNames(styles.button)}
                  label={t(buttonLabel)}
                  isDisabled={isCurrentPlan}
                  onClick={() => {
                    console.log("CLICKED!");
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TariffPlans;
