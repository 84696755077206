import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ReactCurvedText from "react-curved-text";

import { ChangeThemeButtonProps } from "../types";
import { useAppSelector } from "../../../store/hooks";
import constants from "../../../utils/constants";

import styles from "./index.module.scss";

const ChangeThemeButton = ({ onClick }: ChangeThemeButtonProps) => {
  const { t } = useTranslation();
  const { current } = useAppSelector(state => state.colorTheme);

  return (
    <div className={styles.container} onClick={onClick}>
      <div
        className={classNames(
          styles.nextThemePreview,
          styles[`nextThemePreview_${current}`],
        )}
      />

      <div
        className={classNames(styles.ellipse, styles[`ellipse_${current}`])}
      />

      <div className={styles.textContainer}>
        <ReactCurvedText
          width={100}
          height={100}
          cx={50}
          cy={50}
          rx={50}
          ry={50}
          startOffset={0}
          reversed={true}
          svgProps={{
            className: classNames(styles.text, styles[`text_${current}`]),
          }}
          text={t(constants.theme.THEME_BUTTON_CONTENT[current])}
        />
      </div>
    </div>
  );
};

export default ChangeThemeButton;
