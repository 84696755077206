import React from "react";
import styles from "./index.module.scss";
import { useAppSelector } from "../../../../../store/hooks";
import classNames from "classnames";

const Background = () => {
  const { current, isTransitionActive } = useAppSelector(
    state => state.colorTheme,
  );

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.grid,
          styles[`grid_${current}`],
          isTransitionActive && styles.transitionActive,
        )}
      >
        <div className={styles.Future_light} />
        <div className={styles.block_1}>
          <span />
        </div>
        <div className={styles.block_2}>
          <span />
        </div>
        <div className={styles.block_3}>
          <span />
        </div>
        <div className={styles.block_4}>
          <span />
        </div>
        <div className={styles.block_5}>
          <span />
        </div>
      </div>
    </div>
  );
};

export default Background;
