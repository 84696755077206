import React from "react";
import { Oval } from "react-loader-spinner";

import { LoadingIndicatorProps } from "../types";

const LoadingIndicator = ({
  width,
  height,
  color,
  secondaryColor,
  strokeWidth,
  strokeWidthSecondary,
  className,
}: LoadingIndicatorProps) => {
  return (
    <Oval
      ariaLabel="oval-loading"
      width={width}
      height={height}
      color={color}
      secondaryColor={secondaryColor}
      strokeWidth={strokeWidth}
      strokeWidthSecondary={strokeWidthSecondary}
      wrapperClass={className}
    />
  );
};

export default LoadingIndicator;
