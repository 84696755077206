import React, { useState } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../store/hooks";
import { BusinessSliderItem } from "../../../../../utils/constants/types";
import SvgIcon from "../../../../shared/SvgIcon";
import classNames from "classnames";

const Slide = ({ item }: { item: BusinessSliderItem }) => {
  const { title, description, backgroundColor, icon: iconObject } = item;
  const { t } = useTranslation();
  const { current } = useAppSelector(state => state.colorTheme);
  const [isExpanded, setIsExpanded] = useState(false);
  const color = backgroundColor[current];
  const icon = iconObject[current];
  const [showAnimation, setShowAnimation] = useState(false);
  const [showAnimationBack, setShowAnimationBack] = useState(false);

  return (
    <div className={styles.slide}>
      <div
        className={classNames(
          styles.container,
          styles.container_front,
          isExpanded && styles.container_front_isExpanded,
          styles[`container_${current}`],
        )}
      >
        <div
          className={classNames(styles.circle, showAnimation && styles.active)}
        />
        <div
          className={classNames(styles.slideBg, styles[`slideBg_${color}`])}
        />
        <div className={styles.slideIconContainer}>
          <SvgIcon type={icon} className={styles.slideIcon} />
        </div>
        <div className={styles.slideText}>
          <p className={styles.slideTitle}>{t(title)}</p>
          <p className={styles.slideDescription}>{t(description)}</p>
        </div>
        <div
          onMouseEnter={() => setShowAnimation(true)}
          onMouseLeave={() => setShowAnimation(false)}
          onClick={() => setIsExpanded(!isExpanded)}
          className={styles.collapseIconContainer}
        >
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
        </div>
      </div>
      <div
        className={classNames(
          styles.container,
          styles.container_back,
          isExpanded && styles.container_back_isExpanded,
          styles[`container_${current}`],
        )}
      >
        <div
          className={classNames(
            styles.circle,
            showAnimationBack && styles.active,
          )}
        />
        <div
          className={classNames(styles.slideBg, styles[`slideBg_${color}`])}
        />
        <div className={styles.slideText}>
          <p className={styles.slideTitle}>{t(title)}</p>
          <p className={styles.slideDescription}>{t(description)}</p>
        </div>
        <div
          onMouseEnter={() => setShowAnimationBack(true)}
          onMouseLeave={() => setShowAnimationBack(false)}
          onClick={() => setIsExpanded(!isExpanded)}
          className={styles.collapseIconContainer}
        >
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
          <div className={styles.collapseIconItem} />
        </div>
      </div>
    </div>
  );
};

export default Slide;
