import styled from "styled-components";

export const SidebarImageWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 48px;
  height: 100%;
  padding-left: 36px;
  padding-top: 56px;
  background: #23216a;
  overflow: hidden;
  h4 {
    font-weight: 590;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.432px;
    color: #fff;
    width: 100%;
    margin-left: 26px;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
    margin: 10px 0 10px 26px;
  }

  div {
    width: 100%;
    height: 100%;
    min-height: 300px;
    background-position: right bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;
