import React, { useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { FilePickerProps } from "../types";

import SvgIcon from "../SvgIcon";
import RadioGroup from "../RadioGroup";
import Switch from "../Switch";
import Input from "../Input";

import styles from "./index.module.scss";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import actions from "../../../store/actions";
import { UPLOAD_FILE_RADIO_GROUP } from "../../../utils/constants/content";
import Button from "../Button";
import { validateUploadFile } from "../../../utils/validation";
import dayjs from "dayjs";
import api from "../../../apiSingleton";

const FilePicker = ({ value, className, onChange }: FilePickerProps) => {
  const dispatch = useAppDispatch();
  const { current } = useAppSelector(state => state.colorTheme);
  const id = nanoid();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [fileStoragePeriod, setFileStoragePeriod] = useState("14");
  const [isLocked, setIsLocked] = useState(true);
  const [pass, setPass] = useState("");
  const [recepient, setRecepient] = useState("");
  const [sender, setSender] = useState("");
  const [fileLink, setFileLink] = useState("");
  const [file, setFile] = useState<string | Blob>("");

  const setToInitial = () => {
    setPass("");
    setFileStoragePeriod("14");
    setIsLocked(true);
    setPass("");
    setRecepient("");
    setSender("");
  };

  const handleAttachFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files?.[0]) {
      setFile(files[0]);
      onChange(e);
    }
  };

  const sendFile = async () => {
    const form = new FormData();

    setIsLoading(true);

    form.append("file", file);
    form.append("pass", isLocked ? pass : "");
    form.append("sender", sender);
    form.append("recipient", recepient);
    form.append(
      "deadline",
      `${dayjs().add(+fileStoragePeriod, "days").format("DD.MM.YYYY hh:mm")}`,
    );

    const {
      data: { link },
    } = await api.file.send(form);

    dispatch(actions.toast.showToast({ message: "File added" }));
    setIsLoading(false);
    setFileLink(`${process.env.REACT_APP_API_URL}${link}`);
    setToInitial();
  };

  const handlePeriodChange = (period: string) => {
    setFileStoragePeriod(period);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(fileLink);
    dispatch(actions.toast.showToast({ message: "Link copied" }));
  };

  const handleIsLockedChange = (value: boolean) => {
    setIsLocked(value);
  };

  const validateFields = () => {
    validateUploadFile({
      data: { recepient, sender, pass },
      onSuccess: () => {
        sendFile();
      },
      onError: () => {
        dispatch(
          actions.toast.showToast({ message: "Please fill data corectly" }),
        );
      },
    });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const fileObject = droppedFiles[0];
      setFile(fileObject);
      const syntheticEvent = {
        target: {
          files: droppedFiles,
          type: "file",
        },
      };
      onChange(syntheticEvent as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.container_empty]: !value.type,
      })}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {!value.type && (
        <>
          <label className={styles.label} htmlFor={id} />

          <div className={styles.content}>
            <SvgIcon type="plusRounded" />

            <p>{t("Drag the File or")}</p>
            <p>{t("Click Download")}</p>
          </div>

          <SvgIcon type="pickerSpiralArrow" className={styles.arrow} />
          <SvgIcon type={`pickerDoc_${current}`} className={styles.doc} />

          <input
            id={id}
            type="file"
            onInput={handleAttachFile}
            multiple={false}
            hidden
          />
        </>
      )}

      {value.type && (
        <div className={styles.fileInfo}>
          <div className={styles.formatIconContainer}>
            <SvgIcon type="pickerDoc" className={styles.formatIcon} />

            <p className={styles.formatName}>{value.type.split("/")[1]}</p>
          </div>

          <div className={styles.formContainer}>
            <div className={styles.formContainer_top}>
              <RadioGroup
                items={UPLOAD_FILE_RADIO_GROUP}
                value={fileStoragePeriod}
                onChange={handlePeriodChange}
              />
              <Switch
                checked={isLocked}
                onChange={handleIsLockedChange}
                icons={{ left: "lockOff", right: "lockOn" }}
              />
            </div>

            <Input
              value={pass}
              placeholder={t("Password")}
              disabled={!isLocked}
              onChange={setPass}
            />

            <Input
              value={recepient}
              placeholder={t("Recipient's e-mail")}
              onChange={setRecepient}
            />

            <Input
              value={sender}
              placeholder={t("Sender's e-mail")}
              onChange={setSender}
            />

            <Button
              label={t("Upload file")}
              isLoading={isLoading}
              onClick={validateFields}
            />

            {fileLink && (
              <Input
                value={fileLink}
                icon="copy"
                disabled={true}
                onIconClick={handleCopyLink}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilePicker;
