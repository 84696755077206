import React from "react";
import { useTranslation } from "react-i18next";

import { FOOTER_LINKS } from "../../../utils/constants/content";

import SvgIcon from "../../shared/SvgIcon";
import InternalLink from "../../shared/InternalLink";

import styles from "./index.module.scss";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.container}>
      <div className={styles.content}>
        <SvgIcon type="logoFull" className={styles.logo} />

        <nav className={styles.nav}>
          {FOOTER_LINKS.map(link => {
            const { label, path } = link;

            return (
              <InternalLink key={label} text={t(label)} to={path} replace />
            );
          })}
        </nav>
      </div>

      <div className={styles.copyright}>
        <p>© 4hub</p>
      </div>
    </footer>
  );
};

export default Footer;
