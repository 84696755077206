import { media } from "../../../styles/sizes";
import styled from "styled-components";

import { ReactComponent as Logo } from "../../../assets/icons/signin_logo.svg";

export const DesktopHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  color: #fff;
  @media screen and (${media.md}) {
    margin-bottom: 0;
  }
`;

export const LogoIcon = styled(Logo)`
  width: 133px;
`;

export const AuthLayoutWrapp = styled.div`
  min-height: 100vh;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background: #0b0859;
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 70px;
  @media screen and (${media.md}) {
    padding: 36px 64px;
    flex-direction: row;
    height: 100vh;
    gap: 0;
  }
`;

export const AuthLayoutContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  @media screen and (${media.md}) {
    padding: 0 30px 0 10px;
    width: 45%;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
`;

export const AuthLayoutSlider = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  max-height: 1200px;
  @media screen and (${media.md}) {
    width: 55%;
  }
`;

export const AuthLayoutInfo = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  margin-top: 15px;

  a {
    color: #36faff;
  }
`;
