import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";

import styles from "./index.module.scss";
const MoreThan = () => {
  const { current } = useAppSelector(state => state.colorTheme);
  const { t } = useTranslation();
  /*   console.log('first') */
  return (
    <div
      className={classNames(styles.container, styles[`container_${current}`])}
    >
      <div className={styles.grid}>
        <div />
        <div />
        <span className={styles.future_light} />
      </div>
      <h4>{t("More than 600,000 teams use 4HUB")}</h4>
    </div>
  );
};

export default MoreThan;
