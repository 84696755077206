import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import actions from "../actions";
import { RootState } from "../store";
import { ToastState } from "../types/toast";

const initialState: ToastState = {
  message: "",
  type: "default",
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    _showToast(state, action: PayloadAction<ToastState>) {
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    _hideToast(state, action: PayloadAction<ToastState>) {
      state.message = action.payload.message;
      state.type = "default";
    },
  },

  extraReducers: builder => {
    builder.addCase(
      actions.toast.showToast.fulfilled,
      (state, action: PayloadAction<ToastState>) => {
        state.message = action.payload.message;
        state.type = action.payload.type;
      },
    );
    builder.addCase(
      actions.toast.hideToast.fulfilled,
      (state, action: PayloadAction<ToastState>) => {
        state.message = action.payload.message;
        state.type = "default";
      },
    );
  },
});

export const toastSelector = (state: RootState) => state.toast;

export const { _showToast, _hideToast } = toastSlice.actions;

export default toastSlice.reducer;
