import Slide_1 from "../../../assets/images/slide_1.png";
import Slide_2 from "../../../assets/images/slide_2.png";
import Slide_3 from "../../../assets/images/slide_3.png";
import Slide_4 from "../../../assets/images/slide_4.png";
import Slide_5 from "../../../assets/images/slide_5.png";
import Slide_6 from "../../../assets/images/slide_6.png";
import Slide_7 from "../../../assets/images/slide_7.png";
import { t } from "i18next";
import { Slide } from "../../shared/AuthSlider/AuthSlider";

export const authData = () =>
  [
    {
      title: t("authSlider.slide_1.title"),
      text: t("authSlider.slide_1.text"),
      src: Slide_1,
      alt: "chat",
      size: "contain",
      position: "right center",
      isMargin: true,
    },
    {
      title: t("authSlider.slide_2.title"),
      text: t("authSlider.slide_2.text"),
      src: Slide_2,
      alt: "control",
      size: "contain",
      position: "right bottom",
      isMargin: true,
    },
    {
      title: t("authSlider.slide_3.title"),
      text: t("authSlider.slide_3.text"),
      src: Slide_3,
      alt: "chat",
      size: "contain",
      position: "right bottom",
      isMargin: true,
    },
    {
      title: t("authSlider.slide_4.title"),
      text: t("authSlider.slide_4.text"),
      src: Slide_4,
      alt: "task board",
      size: "70%",
      position: "center",
    },
    {
      title: t("authSlider.slide_5.title"),
      text: t("authSlider.slide_5.text"),
      src: Slide_5,
      alt: "calendar",
      size: "contain",
      position: "center",
    },
    {
      title: t("authSlider.slide_6.title"),
      text: t("authSlider.slide_6.text"),
      src: Slide_6,
      alt: "integration",
      size: "contain",
      position: "center right",
      isMargin: true,
    },
    {
      title: t("authSlider.slide_7.title"),
      text: t("authSlider.slide_7.text"),
      src: Slide_7,
      alt: "team",
      size: "cover",
      position: "top center",
    },
  ] as Slide[];
