import React, { useEffect, useState } from "react";
import i18n from "../../../utils/i18n";
import { LanguageItem } from "../../base/types";
import useCurrentLanguage from "../../../hooks/useCurrentLanguage";
import Select from "../DropDown";

export const LanguageSelect = () => {
  const currentLang = useCurrentLanguage();

  const [options, setOptions] = useState<LanguageItem[]>([]);
  const [selectedLang, setSelectedLang] = useState("");

  const initLangs = () => {
    const resources = i18n.options.resources as {
      [key: string]: {
        translation: Record<string, string>;
      };
    };

    const langsOptions = Object.keys(resources).map((lang: string) => ({
      value: lang,
      label: lang,
    }));

    setSelectedLang(currentLang);
    setOptions(langsOptions);
  };
  const handleLangChange = (value: string) => {
    setSelectedLang(value);
    i18n.changeLanguage(value);
    localStorage.setItem("APP_LANG", value);
  };

  useEffect(initLangs, []);
  return (
    <Select
      value={selectedLang}
      options={options}
      onChange={handleLangChange}
    />
  );
};
