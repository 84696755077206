import styled from "styled-components";
import { media } from "../../../styles/sizes";

export const Wrap = styled.div`
  height: 100%;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  h4 {
    font-weight: 590;
    font-size: 36px;
    line-height: 43px;
    color: #fff;
    margin-bottom: 15px;
  }

  @media screen and (${media.md}) {
    margin-top: 150px;
  }
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  margin-bottom: 30px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  width: 100%;

  button {
    width: 100%;

    &:hover svg path {
      fill: #36faff;
      transition: fill 0.3s ease-in-out;
    }
  }
`;

export const OrText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  margin: 15px 0;
  text-align: center;
`;

export const WorkspaceBox = styled.div`
  position: relative;
  input {
    padding-right: 88px;
  }
`;

export const WorkspaceText = styled.div`
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  width: max-content;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.4);
  padding: 14px 12px;
  font-size: 12px;
  color: #f6f6f6;
`;

export const SuccessText = styled.p`
  width: 456px;
  padding: 14px 20px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.4);
  color: #f6f6f6;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  opacity: 0.6;
  margin-top: 30px;
`;
