import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ButtonProps } from "../types";
import { useAppSelector } from "../../../store/hooks";

import LoadingIndicator from "../LoadingIndicator";

import styles from "./index.module.scss";

const Button = ({
  label,
  isLoading,
  isDisabled,
  className,
  onClick,
}: ButtonProps) => {
  const currentTheme = useAppSelector(state => state.colorTheme.current);
  const { t } = useTranslation();

  const handleClick = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  return (
    <button
      className={classNames(
        styles.button,
        className,
        styles[`button_${currentTheme}`],
        {
          [styles.button_disabled]: isDisabled || isLoading,
        },
      )}
      onClick={handleClick}
    >
      <div className={styles.circle} />
      {!isLoading && <span>{t(label)}</span>}
      {isLoading && (
        <LoadingIndicator
          width={30}
          height={30}
          color={currentTheme === "future" ? "#1e1e1e" : "#110d7e"}
          secondaryColor={currentTheme === "future" ? "#1e1e1e" : "#110d7e"}
          strokeWidth={4}
          strokeWidthSecondary={4}
        />
      )}
    </button>
  );
};

export default Button;
