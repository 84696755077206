import {
  AuthLayoutContent,
  AuthLayoutInfo,
  AuthLayoutSlider,
  AuthLayoutWrapp,
  DesktopHeader,
  LogoIcon,
  Wrapper,
} from "./AuthLayout.styled";
import { authData } from "./data";
import { t } from "i18next";
import { Link, useLocation } from "react-router-dom";
import constants from "../../../utils/constants";
import { AuthSlider } from "../../shared/AuthSlider";
import { FC } from "react";
import { LanguageSelect } from "../../shared/LanguageSelect/LanguageSelect";

interface IAuthLayoutProps {
  children: JSX.Element;
}
const AuthLayout: FC<IAuthLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <AuthLayoutWrapp>
      <AuthLayoutContent>
        <DesktopHeader>
          <Link to="https://fslanding.mh.net.ua/">
            <LogoIcon />
          </Link>
          <LanguageSelect />
        </DesktopHeader>
        <Wrapper>{children}</Wrapper>
        <AuthLayoutInfo>
          {pathname === `/${constants.routes.SIGNUP}` ? (
            <>
              {t("auth.haveAccount")}&nbsp;
              <Link to={`/${constants.routes.SIGNIN}`}>{t("short.enter")}</Link>
            </>
          ) : (
            <>
              {t("auth.notAccount")}&nbsp;
              <Link to={`/${constants.routes.SIGNUP}`}>
                {t("short.registration")}
              </Link>
            </>
          )}
        </AuthLayoutInfo>
      </AuthLayoutContent>
      <AuthLayoutSlider>
        <AuthSlider data={authData()} />
      </AuthLayoutSlider>
    </AuthLayoutWrapp>
  );
};

export default AuthLayout;
