import {
  SidebarListItem,
  SidebarListRightIcon,
  SidebarListText,
  SidebarListTitle,
  SidebarListWrap,
} from "./SidebarList.styled";
import { createElement, FC, SVGProps } from "react";

interface SidebarListProps {
  title: string;
  text: string;
  icon: FC<SVGProps<SVGSVGElement>>;
}

const colors = ["#DD05B0", "#740FFF", "#D010FF", "#3B42EA", "#0D47A1"];

export const SidebarList = ({ data }: { data: SidebarListProps[] }) => {
  return (
    <SidebarListWrap>
      {data.map((item, index) => (
        <SidebarListItem
          key={item.title}
          style={{ backgroundColor: colors[index % colors.length] }}
        >
          <SidebarListTitle>
            {createElement(item.icon, {
              style: { width: "32px", height: "32px", marginRight: "10px" },
            })}
            {item.title}
          </SidebarListTitle>
          <SidebarListRightIcon />
          <SidebarListText>{item.text}</SidebarListText>
        </SidebarListItem>
      ))}
    </SidebarListWrap>
  );
};
