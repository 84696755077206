import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { TabsCarouselProps } from "../types";

import styles from "./index.module.scss";
import { useAppSelector } from "../../../store/hooks";

const HEADER_PADDING = 8;

const TabsCarousel = ({ items }: TabsCarouselProps) => {
  const { current } = useAppSelector(state => state.colorTheme);
  const { t } = useTranslation();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          className={styles.header}
          style={{ paddingLeft: HEADER_PADDING, paddingRight: HEADER_PADDING }}
        >
          {items.map(({ tab }, index) => {
            return (
              <span
                key={tab}
                className={styles.tabHeaderItem}
                style={{ width: `calc(100% / ${items.length})` }}
                onClick={() => setActiveTabIndex(index)}
              >
                {t(tab)}
              </span>
            );
          })}

          <div
            className={styles.headerActiveBackground}
            style={{
              width: `calc(100% / ${items.length} - ${
                (HEADER_PADDING * 2) / items.length
              }px)`,
              transform: `translateX(calc(100% * ${activeTabIndex}))`,
            }}
          />
        </div>

        {items.map(({ title, description, image }, index) => {
          return (
            <div
              key={title}
              className={classNames(styles.tabContent, {
                [styles.tabContent_active]: index === activeTabIndex,
              })}
            >
              <div className={styles.text}>
                <p className={styles.title}>{t(title)}</p>

                <p className={styles.description}>{t(description)}</p>
              </div>

              <img src={image[current]} className={styles.image} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabsCarousel;
