import React from "react";
import Hero from "../../blocks/Hero";
import UploadFile from "../../blocks/UploadFile";
import MoreThan from "../../blocks/MoreThan";
import Business from "../../blocks/Business";
import Differentiation from "../../blocks/Differentiation";
import Subscribe from "../../blocks/Subscribe";
import Personal from "../../blocks/Personal";
import Advantages from "../../blocks/Advantages";
import TariffPlans from "../../blocks/TariffPlans";

const MainPage = () => {
  return (
    <>
      <Hero />
      <UploadFile />
      <MoreThan />
      <Business />
      <Differentiation />
      <Subscribe />
      <Personal />
      <Advantages />
      <TariffPlans />
    </>
  );
};

export default MainPage;
