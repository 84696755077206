import React, { useEffect, useState } from "react";

import styles from "./index.module.scss";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { generateUniqueId } from "../../../utils/functions";
import classNames from "classnames";
import ChangeThemeButton from "../ChangeThemeButton";
import actions from "../../../store/actions";

function BackgroundContainer() {
  const dispatch = useAppDispatch();
  const { current } = useAppSelector(state => state.colorTheme);
  const [backMode, setBackMode] = useState<JSX.Element[]>([]);
  const [frontMode, setFrontMode] = useState<JSX.Element[]>([]);

  const handleThemeChange = () => {
    const nextColorTheme = current === "cyberpunk" ? "future" : "cyberpunk";
    dispatch(actions.colorTheme.updateColorTheme(nextColorTheme));
  };

  useEffect(() => {
    const updateActiveModes = (prevModes: JSX.Element[], mode: string) => {
      const newModes = [...prevModes];
      newModes.splice(0, Math.max(0, newModes.length - 5));
      newModes.push(
        <div className={styles.container} key={generateUniqueId()}>
          <div className={classNames(mode, styles[`mode_${current}`])} />
        </div>,
      );
      return newModes;
    };

    setBackMode(prevModes => updateActiveModes(prevModes, styles.BackMode));
    setFrontMode(prevModes => updateActiveModes(prevModes, styles.FrontMode));
  }, [current]);

  return (
    <>
      <div className={styles.BackWrapper}>{backMode.map(mode => mode)}</div>
      <div className={styles.FrontWrapper}>{frontMode.map(mode => mode)}</div>
      <ChangeThemeButton onClick={() => handleThemeChange()} />
    </>
  );
}

export default BackgroundContainer;
