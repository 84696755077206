import { ReactComponent as ArchiveIcon } from "../../../assets/icons/archive.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar_3.svg";
import { ReactComponent as ChatIcon } from "../../../assets/icons/chat.svg";
import { ReactComponent as FileIcon } from "../../../assets/icons/file_2.svg";
import { ReactComponent as JointIcon } from "../../../assets/icons/joint.svg";
import { ReactComponent as LibraryIcon } from "../../../assets/icons/library-2.svg";
import { ReactComponent as MagazineIcon } from "../../../assets/icons/magazine.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import { ReactComponent as ProgramIcon } from "../../../assets/icons/program.svg";
import { ReactComponent as TaskIcon } from "../../../assets/icons/task.svg";
import Image_7 from "../../../assets/images/archive.png";
import Image_2 from "../../../assets/images/calendar.png";
import Image_5 from "../../../assets/images/chat.png";
import Image_1 from "../../../assets/images/filestorage.png";
import Image_8 from "../../../assets/images/joint.png";
import Image_10 from "../../../assets/images/library.png";
import Image_9 from "../../../assets/images/magazine.png";
import Image_6 from "../../../assets/images/mail.png";
import Image_11 from "../../../assets/images/program.png";
import Slide_1 from "../../../assets/images/slide_1.png";
import Slide_2 from "../../../assets/images/slide_2.png";
import Slide_3 from "../../../assets/images/slide_3.png";
import Slide_4 from "../../../assets/images/slide_4.png";
import Slide_5 from "../../../assets/images/slide_5.png";
import Slide_6 from "../../../assets/images/slide_6.png";
import Slide_7 from "../../../assets/images/slide_7.png";
import Image_3 from "../../../assets/images/taskboard.png";
import Image_4 from "../../../assets/images/taskmanager.png";
import { Slide } from "../../shared/AuthSlider/AuthSlider";
import { t } from "i18next";
import { FC, SVGProps } from "react";

export interface IQuestionsData {
  title: string;
  answers: string[];
  images: string[];
  icon: FC<SVGProps<SVGSVGElement>>[];
  position: string[];
  descriptions: string[];
}

export const sliderData = () =>
  [
    {
      title: t("authSlider.slide_1.title"),
      text: t("authSlider.slide_1.text"),
      src: Slide_1,
      alt: "chat",
      size: "contain",
      position: "right center",
      isMargin: true,
    },
    {
      title: t("authSlider.slide_2.title"),
      text: t("authSlider.slide_2.text"),
      src: Slide_2,
      alt: "control",
      size: "contain",
      position: "right bottom",
      isMargin: true,
    },
    {
      title: t("authSlider.slide_3.title"),
      text: t("authSlider.slide_3.text"),
      src: Slide_3,
      alt: "chat",
      size: "contain",
      position: "right bottom",
      isMargin: true,
    },
    {
      title: t("authSlider.slide_4.title"),
      text: t("authSlider.slide_4.text"),
      src: Slide_4,
      alt: "task board",
      size: "70%",
      position: "center",
    },
    {
      title: t("authSlider.slide_5.title"),
      text: t("authSlider.slide_5.text"),
      src: Slide_5,
      alt: "calendar",
      size: "contain",
      position: "center",
    },
    {
      title: t("authSlider.slide_6.title"),
      text: t("authSlider.slide_6.text"),
      src: Slide_6,
      alt: "integration",
      size: "contain",
      position: "center right",
      isMargin: true,
    },
    {
      title: t("authSlider.slide_7.title"),
      text: t("authSlider.slide_7.text"),
      src: Slide_7,
      alt: "team",
      size: "cover",
      position: "top center",
    },
  ] as Slide[];

export const questionData = [
  {
    title: t("questionnaire.question_1.question"),
    answers: [
      t("questionnaire.question_1.answers.answer_1"),
      t("questionnaire.question_1.answers.answer_2"),
      t("questionnaire.question_1.answers.answer_3"),
      t("questionnaire.question_1.answers.answer_4"),
      t("questionnaire.question_1.answers.answer_5"),
    ],
  },
  {
    title: t("questionnaire.question_2.question"),
    answers: [
      t("questionnaire.question_2.answers.answer_1"),
      t("questionnaire.question_2.answers.answer_2"),
      t("questionnaire.question_2.answers.answer_3"),
      t("questionnaire.question_2.answers.answer_4"),
      t("questionnaire.question_2.answers.answer_5"),
      t("questionnaire.question_2.answers.answer_6"),
      t("questionnaire.question_2.answers.answer_7"),
      t("questionnaire.question_2.answers.answer_8"),
      t("questionnaire.question_2.answers.answer_9"),
      t("questionnaire.question_2.answers.answer_10"),
      t("questionnaire.question_2.answers.answer_11"),
    ],
  },
  {
    title: t("questionnaire.question_3.question"),
    answers: [
      t("questionnaire.question_3.answers.answer_1"),
      t("questionnaire.question_2.answers.answer_2"),
    ],
  },
  {
    title: t("questionnaire.question_4.question"),
    answers: [
      t("questionnaire.question_4.answers.answer_1"),
      t("questionnaire.question_4.answers.answer_2"),
      t("questionnaire.question_4.answers.answer_3"),
      t("questionnaire.question_4.answers.answer_4"),
      t("questionnaire.question_4.answers.answer_5"),
      t("questionnaire.question_4.answers.answer_6"),
      t("questionnaire.question_4.answers.answer_7"),
      t("questionnaire.question_4.answers.answer_8"),
      t("questionnaire.question_4.answers.answer_9"),
    ],
  },
  {
    title: t("questionnaire.question_5.question"),
    answers: [
      t("questionnaire.question_5.answers.answer_1"),
      t("questionnaire.question_5.answers.answer_2"),
      t("questionnaire.question_5.answers.answer_3"),
      t("questionnaire.question_5.answers.answer_4"),
      t("questionnaire.question_5.answers.answer_5"),
      t("questionnaire.question_5.answers.answer_6"),
      t("questionnaire.question_5.answers.answer_7"),
      t("questionnaire.question_5.answers.answer_8"),
      t("questionnaire.question_5.answers.answer_9"),
      t("questionnaire.question_5.answers.answer_10"),
      t("questionnaire.question_5.answers.answer_11"),
    ],
    images: [
      Image_1,
      Image_2,
      Image_3,
      Image_4,
      Image_5,
      Image_6,
      Image_7,
      Image_8,
      Image_9,
      Image_10,
      Image_11,
    ],
    position: [
      "right center",
      "right center",
      "right center",
      "center",
      "center",
      "right center",
      "center",
      "right center",
      "center",
      "right bottom",
      "right center",
    ],
    icon: [
      FileIcon,
      CalendarIcon,
      TaskIcon,
      TaskIcon,
      ChatIcon,
      MailIcon,
      ArchiveIcon,
      JointIcon,
      MagazineIcon,
      LibraryIcon,
      ProgramIcon,
    ],
    descriptions: [
      t("questionnaire.question_5.descriptions.descriptions_1"),
      t("questionnaire.question_5.descriptions.descriptions_2"),
      t("questionnaire.question_5.descriptions.descriptions_3"),
      t("questionnaire.question_5.descriptions.descriptions_4"),
      t("questionnaire.question_5.descriptions.descriptions_5"),
      t("questionnaire.question_5.descriptions.descriptions_6"),
      t("questionnaire.question_5.descriptions.descriptions_7"),
      t("questionnaire.question_5.descriptions.descriptions_8"),
      t("questionnaire.question_5.descriptions.descriptions_9"),
      t("questionnaire.question_5.descriptions.descriptions_10"),
      t("questionnaire.question_5.descriptions.descriptions_11"),
    ],
  },
] as IQuestionsData[];
