import { AuthButton } from "../../../../shared/AuthButton";
import { QuestionAnswers, QuestionWrap } from "./Question.styled";

export const Question = ({
  length,
  question,
  answers,
  activeAnswers,
  onSelectAnswer,
}: {
  length: string;
  question: string;
  answers: string[];
  activeAnswers?: string[];
  onSelectAnswer: (answer: string) => void;
}) => {
  return (
    <QuestionWrap>
      <span>{length}</span>
      <h4>{question}</h4>
      <QuestionAnswers>
        {answers.map((item, index) => (
          <AuthButton
            key={index}
            isActive={activeAnswers && activeAnswers.includes(item)}
            answerButton
            onClick={() => onSelectAnswer(item)}
          >
            {item}
          </AuthButton>
        ))}
      </QuestionAnswers>
    </QuestionWrap>
  );
};
