import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import {
  AuthSliderFrame,
  AuthSliderImage,
  AuthSliderSlidePagination,
  AuthSlideText,
  AuthSlideTitle,
  CustomSwiperSlide,
} from "./AuthSlider.styled";
import { useEffect, useState } from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper } from "swiper/react";
import SwiperCore from "swiper/swiper";

export interface Slide {
  title: string;
  text: string;
  src: string;
  alt: string;
  size: "cover" | "contain";
  position: string;
  isMargin?: boolean;
}

export const AuthSlider = ({
  data,
  activeSlideIndex,
  autoplay = true,
}: {
  data: Slide[];
  activeSlideIndex?: number;
  autoplay?: boolean;
}): JSX.Element => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  const onSwiper = (swiper: SwiperCore) => {
    setSwiper(swiper);
  };

  useEffect(() => {
    if (swiper && activeSlideIndex !== undefined) {
      swiper.slideTo(activeSlideIndex);
    }
  }, [activeSlideIndex, swiper]);

  return (
    <Swiper
      pagination={{
        el: "#pagination",
        clickable: true,
      }}
      spaceBetween={30}
      effect={"flip"}
      modules={[Pagination, Autoplay]}
      autoplay={autoplay ? { delay: 6000, disableOnInteraction: false } : false}
      loop={true}
      style={{ height: "100%", position: "relative" }}
      onSwiper={onSwiper}
    >
      {data.map((item, index) => {
        return (
          <CustomSwiperSlide key={index}>
            <AuthSliderFrame />
            <div>
              <AuthSlideTitle>{item.title}</AuthSlideTitle>
              <AuthSlideText>{item.text}</AuthSlideText>
            </div>
            <AuthSliderImage
              style={{
                backgroundImage: `url('${item.src}')`,
                backgroundPosition: item.position,
                backgroundSize: item.size,
                marginLeft:
                  item.isMargin !== undefined && item.isMargin
                    ? "36px"
                    : undefined,
              }}
            />
          </CustomSwiperSlide>
        );
      })}
      <AuthSliderSlidePagination>
        <div id="pagination" />
      </AuthSliderSlidePagination>
    </Swiper>
  );
};
