import {
  AnimationWrapper,
  AuthInputWrap,
  Circle,
  ErrorText,
  EyeButton,
  IconWrapper,
  InputField,
} from "./AuthInput.styled";
import { ChangeEventHandler, useEffect, useState } from "react";

import { ReactComponent as CloseEyeIcon } from "../../../assets/icons/eye-close.svg";
import { ReactComponent as OpenEyeIcon } from "../../../assets/icons/open-eye.svg";

interface AuthInputProps {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  icon?: JSX.Element;
  error?: string;
  type?: string;
}

export const AuthInput = ({
  value,
  onChange,
  placeholder,
  icon,
  error,
  type = "text",
}: AuthInputProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const [position, setPosition] = useState({ left: "25%", top: "30%" });

  const getRandomCombination = () => {
    const combinations = [
      { left: "25%", top: "30%" },
      { left: "75%", top: "30%" },
      { left: "25%", top: "60%" },
      { left: "75%", top: "60%" },
    ];

    const randomIndex = Math.floor(Math.random() * combinations.length);
    return combinations[randomIndex];
  };

  useEffect(() => {
    const randomCombination = getRandomCombination();
    setPosition(randomCombination);
  }, []);

  return (
    <AuthInputWrap>
      <AnimationWrapper>
        <Circle style={position} />
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <InputField
          value={value}
          onChange={onChange}
          $error={!!error}
          $withicon={!!icon}
          placeholder={placeholder}
          type={type === "password" && showPassword ? "text" : type}
        />
        {type === "password" && (
          <EyeButton onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
          </EyeButton>
        )}
      </AnimationWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </AuthInputWrap>
  );
};
