import { Circle, Icon, StyledAuthButton } from "./AuthButton.styled";
import { useEffect, useState } from "react";

export const AuthButton = ({
  children,
  isActive,
  disabled,
  onClick,
  signUpButton,
  confirmButton,
  questionnaireButton,
  answerButton,
}: {
  children: JSX.Element | string;
  disabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  signUpButton?: boolean;
  confirmButton?: boolean;
  questionnaireButton?: boolean;
  answerButton?: boolean;
}): JSX.Element => {
  const [position, setPosition] = useState({ left: "25%", top: "30%" });

  const getRandomCombination = () => {
    const combinations = [
      { left: "25%", top: "30%" },
      { left: "75%", top: "30%" },
      { left: "25%", top: "60%" },
      { left: "75%", top: "60%" },
    ];

    const randomIndex = Math.floor(Math.random() * combinations.length);
    return combinations[randomIndex];
  };

  useEffect(() => {
    const randomCombination = getRandomCombination();
    setPosition(randomCombination);
  }, []);

  return (
    <StyledAuthButton
      disabled={disabled}
      $isActive={isActive}
      $signUpButton={signUpButton}
      $confirmButton={confirmButton}
      $questionnaireButton={questionnaireButton}
      $answerButton={answerButton}
      onClick={onClick}
    >
      <Circle style={{ ...position }} />
      {isActive && <Icon />}
      <div>{children}</div>
    </StyledAuthButton>
  );
};
