import { createAsyncThunk } from "@reduxjs/toolkit";

import { ToastState } from "../types/toast";

export const showToast = createAsyncThunk(
  "toast/showToast",
  async ({ message }: ToastState) => {
    return { message };
  },
);

export const hideToast = createAsyncThunk("toast/hideToast", async () => {
  return { message: "" };
});
