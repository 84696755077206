import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import constants from "../../utils/constants";
import { ColorThemePayload } from "../types/colorTheme";

export const setTransitionActive = createAction<boolean>(
  "colorTheme/setTransitionActive",
);

export const updateColorTheme = createAsyncThunk(
  "colorTheme/updateColorTheme",
  async (newColorTheme: ColorThemePayload, { dispatch }) => {
    localStorage.setItem(constants.theme.CURRENT_COLOR_THEME, newColorTheme);
    dispatch(setTransitionActive(true));

    setTimeout(() => {
      dispatch(setTransitionActive(false));
    }, 400);
    return newColorTheme;
  },
);

export const initColorTheme = createAsyncThunk(
  "colorTheme/updateColorTheme",
  () => {
    const currentTheme =
      localStorage.getItem(constants.theme.CURRENT_COLOR_THEME) || "future";

    return currentTheme;
  },
);

export const toggleLogoRotation = createAction("colorTheme/toggleLogoRotation");
