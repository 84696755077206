import React, { useState } from "react";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { UPLOAD_FILE_ADVANTAGES } from "../../../utils/constants/content";

import SvgIcon from "../../shared/SvgIcon";
import FilePicker from "../../shared/FilePicker";
import Advantage from "./molecules/Advantage";

import styles from "./index.module.scss";

const DOC_ICONS = {
  future: "docFuture",
  cyberpunk: "docCyberpunk",
};

const UploadFile = () => {
  const { current } = useAppSelector(state => state.colorTheme);
  const { t } = useTranslation();

  const [file, setFile] = useState({
    type: "",
  });

  const handleAttachFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files?.[0]) {
      setFile(files[0]);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          className={classNames(
            styles.pickerContent,
            styles[`pickerContent_${current}`],
            { [styles.pickerContent_empty]: !file.type },
          )}
        >
          <div className={styles.text}>
            <p className={styles.title}>{t("Upload your file for free now")}</p>

            <ol className={styles.list}>
              <li>
                {t(
                  'Click on "add files" and select the files that you want to send. You can also drag your files or directories from your computer to this window.',
                )}
              </li>

              <li>{t("Select storage options.")}</li>

              <li>{t("Copy the file link.")}</li>
            </ol>
          </div>
          <FilePicker
            value={file}
            className={styles.picker}
            onChange={handleAttachFile}
          />

          <SvgIcon
            type={DOC_ICONS[current]}
            className={classNames(styles.docIcon, {
              [styles.docIcon_hidden]: file.type,
            })}
          />
        </div>
        <div className={styles.advantages}>
          {Object.keys(UPLOAD_FILE_ADVANTAGES).map(advantageKey => {
            const {
              title,
              description,
              image,
              imageProps = { style: {} },
            } = UPLOAD_FILE_ADVANTAGES[advantageKey];

            return (
              <Advantage
                key={title}
                title={title}
                description={description}
                image={image[current]}
                imageProps={imageProps}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
