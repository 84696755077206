import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import actions from "../../../store/actions";

import { ReactComponent as LogoFull } from "../../../assets/icons/logo-full.svg";
import Input from "../../shared/Input";

import styles from "./index.module.scss";

const Subscribe = () => {
  const dispatch = useAppDispatch();
  const { current } = useAppSelector(state => state.colorTheme);
  const { t } = useTranslation();

  const [email, setEmail] = useState("");

  const handleSignUp = () => {
    dispatch(actions.toast.showToast({ message: "Section under development" }));
  };

  return (
    <div
      className={classNames(styles.container, styles[`container_${current}`])}
    >
      <div className={classNames(styles.content, styles[`content_${current}`])}>
        <div className={styles.logoContainer}>
          <LogoFull className={styles.logo} />
        </div>

        <div className={styles.inner}>
          <div className={styles.text}>
            <p className={styles.title}>
              {t("For business and teams of any size")}
            </p>

            <p className={styles.description}>
              {t(
                "Industry-leading security and privacy standards for business and personal use.",
              )}
            </p>
          </div>

          <Input
            value={email}
            placeholder={t("Your email")}
            className={classNames(styles.input, styles[`input_${current}`])}
            onChange={setEmail}
            button={{ label: "Subscribe", onClick: handleSignUp }}
          />
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
