import { useEffect, useState } from "react";
import { IQuestionsData } from "../../data";
import { IAnswer } from "../../Questionnaire";
import { SidebarImage } from "./components/SidebarImage";
import { SidebarList } from "./components/SidebarList";

export const QuestionnareSidebar = ({
  question,
  answer,
}: {
  question: IQuestionsData;
  answer: IAnswer;
}) => {
  const [answerIndices, setAnswerIndices] = useState<number[]>([]);

  useEffect(() => {
    const indices = answer.answers.map(answerOption =>
      question.answers.indexOf(answerOption),
    );
    setAnswerIndices(indices);
  }, [question, answer]);

  const generateSidebarContent = () => {
    return answerIndices.map(index => ({
      title: question.answers[index],
      text: question.descriptions[index],
      icon: question.icon[index],
      position: question.position[index],
    }));
  };

  const renderSidebarContent = () => {
    if (answerIndices.length === 1) {
      const index = answerIndices[0];
      return (
        <SidebarImage
          data={{
            title: question.answers[index],
            text: question.descriptions[index],
            img: question.images[index],
            position: question.position[index],
          }}
        />
      );
    } else {
      const sidebarContent = generateSidebarContent();
      return <SidebarList data={sidebarContent} />;
    }
  };

  return renderSidebarContent();
};
