import React from "react";
import classNames from "classnames";

import { FolderProps } from "../../../types";
import { useAppSelector } from "../../../../../store/hooks";

import SvgIcon from "../../../../shared/SvgIcon";

import styles from "./index.module.scss";
import { scrollIntoView } from "seamless-scroll-polyfill";

const Folder = ({
  targetId,
  topIcon,
  bottomIcon,
  title,
  description,
  frontColor,
}: FolderProps) => {
  const { current } = useAppSelector(state => state.colorTheme);

  const handleClick = () => {
    const target = document.getElementById(targetId);

    if (target) {
      scrollIntoView(target, { behavior: "smooth" });
    }
  };

  return (
    <div className={styles.container}>
      <SvgIcon type="folderTop" className={styles.folderTop} />
      <div
        onClick={handleClick}
        style={{ backgroundColor: frontColor }}
        className={classNames(
          styles.folderFront,
          styles[`folderFront_${current}`],
        )}
      >
        <div className={styles.circle} />
        <div className={styles.text}>
          <p className={classNames(styles.title, styles[`title_${current}`])}>
            {title}
          </p>
          <span
            className={classNames(
              styles.description,
              styles[`description_${current}`],
            )}
          >
            {description}
          </span>
        </div>

        <div className={styles.icons}>
          <SvgIcon type={topIcon} className={styles.topIcon} />

          <SvgIcon type={bottomIcon} className={styles.bottomIcon} />
        </div>
      </div>
    </div>
  );
};

export default Folder;
